import { createStore } from 'vuex';
import auth from './modules/auth';
import user from './modules/user';
import story from './modules/story';  // 确保导入了 story 模块

export default createStore({
  modules: {
    auth,
    story: {
      ...story,
      state: () => ({
        storyList: [],
        // ... 其他初始状态
      })
    },// 确保在这里注册了 story 模块
    user: {
      ...user,
      state: () => ({
        userInfo: null,
        // ... 其他初始状态
      })
    }
  }
})