<template>
  <v-app>
    <!-- 导航栏 - 更简洁的设计 -->
    <v-app-bar 
      app 
      color="white" 
      elevation="0" 
      class="border-bottom"
      fixed
    >
      <v-container>
        <v-row no-gutters align="center">
          <!-- Logo - 更现代的字体 -->
          <v-col cols="auto">
            <v-app-bar-title class="text-h5 font-weight-bold mr-4">comory</v-app-bar-title>
          </v-col>

          <!-- PC端导航菜单 - 更轻量的按钮 -->
          <v-col cols="auto" class="hidden-sm-and-down">
            <v-btn text class="mx-2">功能</v-btn>
            <v-btn text class="mx-2">价格</v-btn>
            <v-btn text class="mx-2">博客</v-btn>
          </v-col>

          <v-spacer></v-spacer>

          <!-- PC端登录按钮 -->
          <v-col cols="auto" class="hidden-sm-and-down">
            <v-btn 
              text 
              class="font-weight-medium mr-4"
              @click="openLoginModal('login')"
            >
              登录
            </v-btn>
            <v-btn 
              color="primary" 
              class="rounded-lg px-6" 
              elevation="0"
              @click="openLoginModal('register')"
            >
              免费开始
            </v-btn>
          </v-col>

          <!-- 移动端菜单按钮 -->
          <v-col cols="auto" class="hidden-md-and-up">
            <v-btn 
              icon 
              class="menu-btn"
              @click="toggleMobileMenu"
            >
              <v-icon>{{ showMobileMenu ? 'mdi-close' : 'mdi-menu' }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <!-- 添加抽屉菜单代码到这里 -->
    <v-navigation-drawer
      v-model="showMobileMenu"
      app
      temporary
      location="top"
      overlay-opacity="0.5"
      class="mobile-menu"
      width="100%"
    >
      <v-list class="py-2">
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          class="menu-item"
          :class="{ 'menu-item--active': isCurrentPage(item.title) }"
          @click="handleMenuClick(item)"
        >
          <v-list-item-title class="text-body-1">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <div class="pa-4">
        <v-btn
          block
          text
          class="mb-3"
          height="44"
          @click="openLoginModal('login')"
        >
          登录
        </v-btn>
        <v-btn
          block
          color="primary"
          height="44"
          @click="openLoginModal('register')"
        >
          免费开始
        </v-btn>
      </div>
    </v-navigation-drawer>

    <!-- 主要内容区域 - Instagram风格的布局 -->
    <v-main class="bg-surface">
      <v-container class="py-16">
        <v-row justify="center" align="center" class="mx-0">
          <!-- 左侧内容 -->
          <v-col cols="12" md="5" class="px-6">
            <div class="order-1">
              <h1 class="text-h3 font-weight-bold mb-6 text-left"> <!-- 改为 text-left -->
                给照片加上年龄<br>
                <span class="text-primary">自动记录</span>你的历程
              </h1>
            </div>

            <!-- 减小详情和按钮的间距 -->
            <div class="order-3 order-md-2 hidden-sm-and-down"> <!-- 添加 hidden-sm-and-down 类 -->
              <p class="text-subtitle-1 text-medium-emphasis mb-8 text-center text-md-left">
                上传照片，照片会告诉你主角当时几岁
              </p>
            </div>

            <!-- 居中按钮并添加点击事件 -->
            <div class="order-4 order-md-3 d-none d-md-flex justify-center justify-md-start"> <!-- 修改这行 -->
              <v-btn
                x-large
                color="primary"
                class="px-12"
                height="56"
                elevation="0"
                rounded="lg"
                @click="openImageSelector"
              >
                上传照片
              </v-btn>
            </div>
          </v-col>

          <!-- 右侧卡片 -->
          <v-col cols="12" md="6" class="order-2 order-md-4">
            <div class="carousel-container">
              <div class="carousel-wrapper">
                <PetAgeCard
                  v-for="(card, index) in originalCards" 
                  :key="index"
                  :card-data="card"
                  :is-active="index === currentIndex"
                  :is-prev="index === (currentIndex - 1 + 3) % 3"
                  :is-next="index === (currentIndex + 1) % 3"
                />
              </div>
            </div>
            
            <!-- 添加移动端详情和按钮 -->
            <div class="d-md-none mt-8"> <!-- 只在移动端显示，并添加上边距 -->
              <!-- 详情文字 -->
              <p class="text-subtitle-1 text-medium-emphasis mb-3 text-left" style="font-size: 1rem !important;">
                上传照片，照片会告诉你主角当时几岁
              </p>
              
              <!-- 上传按钮容器改为居中对齐 -->
              <div class="d-flex justify-center"> <!-- 改为 justify-center -->
                <v-btn
                  x-large
                  color="primary"
                  class="px-12"
                  height="56"
                  width="300"
                  elevation="0"
                  rounded="lg"
                  @click="openImageSelector"
                >
                  上传照片
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- 登录/注册模态框 -->
    <LoginRegisterModal 
      v-model="showLoginModal" 
      :mode="loginModalMode"
      @login-success="handleLoginSuccess" 
    />

    <!-- 添加 AddPost 组件 -->
    <AddPost
      v-if="showAddPost"
      :images="selectedImages"
      @close="handleAddPostClose"
    />
  </v-app>
</template>

<script>
import LoginRegisterModal from '@/components/modals/LoginRegisterModal.vue';
import PetAgeCard from '@/components/cards/PetAgeCard.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ref, onMounted, watch, onUnmounted } from 'vue';
import AddPost from './AddPost.vue'

export default {
  name: 'HomePage',
  components: {
    LoginRegisterModal,
    PetAgeCard,
    AddPost
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const showLoginModal = ref(false);
    const loginModalMode = ref('login');

    const features = [
      { 
        icon: 'mdi-camera',
        title: '智能捕捉', 
        description: '自动识别并记录重要时刻' 
      },
      { 
        icon: 'mdi-folder-multiple-image',
        title: 'AI整理', 
        description: 'AI技术自动分类和标记您的照片' 
      },
      { 
        icon: 'mdi-timeline-text',
        title: '时间线展示', 
        description: '以时间线的形式展示您的生活故事' 
      }
    ];

    const openLoginModal = (mode) => {
      console.log('弹窗类型：', mode)
        // 如果 mode 是事件对象，则默认为 'register'
      loginModalMode.value = typeof mode === 'string' ? mode : 'register';
      showLoginModal.value = true;
    };

    const handleLoginSuccess = () => {
      router.push('/feed');
    };

    const checkAuthAndRedirect = () => {
      if (store.getters['auth/isAuthenticated']) {
        router.push('/feed');
      }
    };

    const showMobileMenu = ref(false);
    const menuItems = [
      { title: '首页', icon: 'mdi-home' },  // 添加首页选项
      { title: '功能', icon: 'mdi-apps' },
      { title: '价格', icon: 'mdi-currency-usd' },
      { title: '博客', icon: 'mdi-post' }
    ];

    const toggleScroll = (disable) => {
      if (disable) {
        // 保存当前滚动位置
        const scrollY = window.scrollY;
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        document.body.style.top = `-${scrollY}px`;
      } else {
        // 恢复滚动位置
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.width = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    };

    const toggleMobileMenu = () => {
      showMobileMenu.value = !showMobileMenu.value;
      toggleScroll(showMobileMenu.value); // 切换滚动状态
    };

    const handleMenuClick = (item) => {
      showMobileMenu.value = false;
      // 根据菜单项进行导航
      switch (item.title) {
        case '首页':
          // 导航到首页页面或滚动到首页区域
          console.log('导航到首页面', item.title);
          break;
        case '功能':
          // 导航功能页面或滚动到功能区域
          console.log('导航到功能页面', item.title);
          break;
        case '价格':
          // 导航到价格页面
          console.log('导航到价格页面', item.title);
          break;
        case '博客':
          // 导航到博客页面
          console.log('导航到博客页面', item.title);
          break;
        default:
          break;
      }
    };

    const isCurrentPage = (title) => {
      const currentPath = window.location.pathname;
      switch (title) {
        case '首页':
          return currentPath === '/' || currentPath === '/home';
        case '功能':
          return currentPath === '/features';
        case '价格':
          return currentPath === '/pricing';
        case '博客':
          return currentPath === '/blog';
        default:
          return false;
      }
    };

    // 定义 autoplayInterval
    let autoplayInterval = null;  // 添这行

    // 修改片数据处理
    const originalCards = ref([
      {
        image: 'https://images.unsplash.com/photo-1514888286974-6c03e2ca1dba',
        title: '欢迎吉喵到家！',
        date: '2022年10月15日',
        humanAge: '32岁3个月',
        petName: '吉喵',
        petAge: '12天'
      },
      {
        image: 'https://images.unsplash.com/photo-1573865526739-10659fec78a5',
        title: '小白第一次游泳',
        date: '2023年6月20日',
        humanAge: '33岁1个月',
        petName: '小白',
        petAge: '3个月'
      },
      {
        image: 'https://images.unsplash.com/photo-1495360010541-f48722b34f7d',
        title: '黑豆学会握手',
        date: '2023年12月1日',
        humanAge: '33岁6个月',
        petName: '黑豆',
        petAge: '6个月'
      }
    ]);

    // 简化轮播逻辑
    const currentIndex = ref(1); // 从中间的卡片开始

    const startAutoplay = () => {
      stopAutoplay();
      autoplayInterval = setInterval(() => {
        currentIndex.value = (currentIndex.value + 1) % 3; // 直接使用 0,1,2 循环
      }, 3000);
    };

    const stopAutoplay = () => {
      if (autoplayInterval) {
        clearInterval(autoplayInterval);
      }
    };

    onMounted(() => {
      checkAuthAndRedirect();
      startAutoplay();
    });

    onUnmounted(() => {
      stopAutoplay();
    });

    watch(() => store.getters['auth/isAuthenticated'], (newValue) => {
      if (newValue) {
        checkAuthAndRedirect();
      }
    });

    // 监听抽屉关闭事件
    watch(showMobileMenu, (newValue) => {
      toggleScroll(newValue);
    });


    const showAddPost = ref(false);
    const selectedImages = ref([]);

    const openImageSelector = () => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      
      input.onchange = (event) => {
        const files = event.target.files;
        if (files && files[0]) {
          selectedImages.value = [{
            url: URL.createObjectURL(files[0]),
            file: files[0]
          }];
          showAddPost.value = true;
        }
      };
      
      input.click();
    };

    const handleAddPostClose = () => {
      showAddPost.value = false;
      selectedImages.value = [];
    };

    return {
      showLoginModal,
      loginModalMode,
      openLoginModal,
      features,
      handleLoginSuccess,
      showMobileMenu,
      menuItems,
      toggleMobileMenu,
      handleMenuClick,
      isCurrentPage,
      currentIndex,
      originalCards,
      startAutoplay,
      stopAutoplay,
      showAddPost,
      selectedImages,
      openImageSelector,
      handleAddPostClose
    };
  }
}
</script>

<style scoped>
/* 全局样式优化 */
:root {
  --primary-color: #0095f6;
  --surface-color: #fafafa;
  --text-primary: #262626;
  --text-secondary: #737373;
}

/* 基础样式 */
.bg-surface {
  background-color: var(--surface-color);
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* 文字样式优化 */
.text-h3 {
  font-size: 0.05rem !important;
  line-height: 1.2;
  color: var(--text-primary);
}

.text-subtitle-1 {
  color: var(--text-secondary);
  font-size: 1.25rem !important;
  line-height: 1.6;
}

/* 按钮样式优化 */
.v-btn {
  text-transform: none;
  letter-spacing: 0;
  font-weight: 500;
}

.v-btn.v-btn--text {
  color: var(--text-primary);
}

.v-btn.v-btn--text:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.v-btn.primary {
  background-color: var(--primary-color) !important;
}

/* 卡片样式优 */
.v-card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.v-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1) !important;
}

/* 响应式优化 */
@media (max-width: 959px) {
  .text-h2 {
    font-size: 2.5rem !important;
  }

  .v-container {
    padding: 32px 16px;
  }

  .text-h3 {
    font-size: 1.75rem !important;
  }
}

/* 修改移动端菜单样式 */
.mobile-menu {
  background-color: white !important;
  top: 60px !important;
  height: auto !important;
  max-height: calc(100vh - 60px) !important;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000 !important;
  position: fixed !important;  /* 添加固定定位 */
  overflow: hidden !important;  /* 确保内容不会 */
}

/* 修改抽屉容器样式 */
.v-navigation-drawer {
  height: auto !important;  /* 改为自适应高度 */
  max-height: calc(100vh - 60px) !important;  /* 限制最大高度 */
  position: fixed !important;  /* 固定定位 */
  contain: none !important;  /* 移除 contain 属性 */
}

/* 修改抽屉内容样式 */
.v-navigation-drawer__content {
  overflow: hidden !important;
  height: auto !important;  /* 改为自适应高度 */
  position: relative !important;
}

/* 确保菜单项样式正确 */
.menu-item {
  min-height: 48px;
  margin: 4px 16px !important;
  border-radius: 8px !important;
}

.menu-item--active {
  background-color: rgba(var(--v-theme-primary), 0.08) !important;
  color: var(--v-theme-primary) !important;
}

/* 修改分割线样式 */
.mobile-menu .v-divider {
  margin: 8px 0;
  opacity: 0.08;
}

/* 修改按钮容器样式 */
.mobile-menu .pa-4 {
  padding: 16px !important;
  background-color: rgba(255, 255, 255, 0.98);
}

/* 修改航栏样式 */
.v-app-bar {
  position: fixed !important;
  top: 0 !important;
  z-index: 1001 !important;
  width: 100% !important;
  background-color: white !important;
}

/* 修改遮罩层样式 */
.v-overlay {
  z-index: 999 !important;
}

/* 修改主内容区域样式 */
.v-main {
  padding-top: 60px !important;
  position: relative !important;
  z-index: 1 !important;
}

/* 防止内容溢出 */
.v-application {
  overflow-x: hidden !important;
}

/* 确保抽屉内容不会溢出 */
.v-navigation-drawer__content {
  overflow: hidden !important;
  contain: content !important;
}

/* 隐藏重复的按钮 */
.v-overlay__content {
  display: none !important;
}

/* 只保留轮播容器相关样式 */
.carousel-container {
  position: relative;
  width: 100%;
  max-width: 280px;
  height: 520px;
  margin: 0 auto;
  padding: 20px 0;
  perspective: 1000px;
}

.carousel-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

/* 移动端响应式优化 */
@media (max-width: 959px) {
  .carousel-container {
    height: 480px;
    padding: 0;
  }
}

.action-btn {
  height: 48px;
  min-width: 140px;
  font-weight: 500;
}

/* 移动端响应式优化 */
@media (max-width: 959px) {
  .action-btn {
    width: 45% !important;
    min-width: 120px !important;
  }
}

</style>