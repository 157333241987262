// 计算时间差，考虑时间先后顺序
export const getTimeAgoString = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  
  // 判断时间先后，确保计算正确的时间差方向
  const isNegative = start > end;
  // console.log('时间比较:', `start: ${start}, end: ${end}`);
  // console.log(`isNegative (start > end): ${isNegative}`);
  
  // 始终用大的日期减小的日期来计算差值
  const [earlierDate, laterDate] = isNegative ? [end, start] : [start, end];
  // console.log(`计算顺序: laterDate(${laterDate}) - earlierDate(${earlierDate})`);
  
  // 计算年月日差值
  let years = laterDate.getFullYear() - earlierDate.getFullYear();
  let months = laterDate.getMonth() - earlierDate.getMonth();
  let days = laterDate.getDate() - earlierDate.getDate();

  // 处理天数为负数的情况
  if (days < 0) {
    months -= 1;
    const lastMonth = new Date(laterDate.getFullYear(), laterDate.getMonth(), 0);
    days += lastMonth.getDate();
  }

  // 处理月份为负数的情况
  if (months < 0) {
    years -= 1;
    months += 12;
  }

  // 检查是否为同一天
  const isSameDay = years === 0 && months === 0 && days === 0;
  if (isSameDay) {
    // console.log('是同一天，返回"今天"');
    return '今天';
  }

  // 组装时间差字符串
  const parts = [];
  if (years > 0) parts.push(`${years}年`);
  if (months > 0) parts.push(`${months}个月`);
  if (days > 0) parts.push(`${days}天`);
  
  const timeString = parts.join(' ');
  // console.log(`时间差字符串: ${timeString}`);
  
  // 根据时间先后关系添加负号
  const finalResult = isNegative ? `将于 ${timeString}后` : timeString;
  // console.log(`最终结果: ${finalResult}`);
  
  return finalResult;
};

export function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleString('zh-CN', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
}



// 网络请求格式化日期， "YYYY-MM-DD HH:mm:ss"
export function formatDateForServer(date) {
  // console.log('日期传入为：',date)
  // 如果输入是字符串，先转换为 Date 对象
  if (typeof date === 'string') {
    date = new Date(date);
  }

  // 确保输入是有效的 Date 对象
  if (!(date instanceof Date) || isNaN(date)) {
    // throw new Error('无效的日期输入');
  }

  // 格式化日期为 "YYYY-MM-DD HH:mm:ss"
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // console.log('日期修正为：',`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`)
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
