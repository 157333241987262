<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="feed" 
    @touchstart="touchStart"
    @touchmove="touchMove"
    @touchend="touchEnd"
  >
    <!-- 下拉刷新提示 -->
    <div class="refresh-tip" :class="{ 'refreshing': isRefreshing }">
      {{ refreshText }}
    </div>

    <!-- 修改顶部背景图区域 -->
    <div class="feed-header">
      <div class="header-image"></div>
      <div class="header-overlay"></div>
      <div class="header-content">
        <h2 class="story-title">{{ storyObj.title }}</h2>
        <p class="story-time">{{ storyObj.leadRole }} {{ storyObj.startTime }}</p>
        <div class="story-fans">
          <div class="fan-avatars">
            <img 
              v-for="(avatar, index) in storyObj.topFanAvatars" 
              :key="index" 
              :src="avatar" 
              :alt="`Fan ${index + 1}`" 
              class="fan-avatar" 
            />
          </div>
          <p class="fans-count">{{ storyObj.fansCount }} 人 ></p>
        </div>
      </div>
    </div>

    <div class="feed-content">
      <!-- 使用 PostItem 件替换原有的 content-card -->
      <template v-for="(item, index) in feedItems" :key="item.id">
        <PostItem
          :post="item"
          :show-year="shouldShowYear(item, feedItems[index - 1])"
          :show-date="shouldShowDate(item, feedItems[index - 1])"
          @open-image-viewer="handleOpenImageViewer"
          @delete-feed="handleDeleteFeed"
        />
      </template>

      <!-- 加载更多触发器 -->
      <div 
        ref="loadMoreTrigger"
        class="loading-more"
      >
        <template v-if="loading">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <span class="ml-2">加载中...</span>
        </template>
      </div>
    </div>

    <!-- 添加 EditPost 组件 -->
    <EditPost 
      v-if="showEditPost" 
      :images="selectedImages" 
      @close="closeEditPost"
      @publish="handlePublish"
    />

    <!-- 添加发布按钮 -->
    <v-btn
      color="primary"
      icon
      elevation="2"
      fixed
      bottom
      right
      class="fab-button"
      @click="openEditPost"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import PostItem from '@/components/PostItem.vue';  // 导入 PostItem 组件
import EditPost from './EditPost.vue';

export default {
  name: 'StoryDetail',
  components: {
    PostItem,  // 注册组件
    EditPost,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    
    // 添加必要的计算属性
    const storyId = computed(() => route.params.id);
    const currentStory = computed(() => store.getters['story/currentStory']);
    const feedList = computed(() => store.getters['story/feedList']);
    const feedHasMore = computed(() => store.getters['story/feedHasMore']);

    // 初始化 storyObj
    const storyObj = ref({
      title: '',
      leadRole: '',
      startTime: '',
      endTime: null,
      img: null,
      userId: null,
      createTime: '',
      updateTime: '',
      topFanAvatars: [
        'https://i.pravatar.cc/150?img=1',
        'https://i.pravatar.cc/150?img=2',
        'https://i.pravatar.cc/150?img=3'
      ],
      fansCount: 100
    });

    const feedItems = ref([]);
    const loading = ref(false);
    const isRefreshing = ref(false);
    const refreshText = ref('下拉刷新');
    const startY = ref(0);
    const currentY = ref(0);
    const page = ref(1);
    const isScrolled = ref(false);
    const loadMoreTrigger = ref(null);

    // 加发布相关的状态
    const showEditPost = ref(false);
    const selectedImages = ref([]);

    // 加载故事数据的方法
    const loadStoryFeeds = async (refresh = true) => {
      if (loading.value) return;
      
      loading.value = true;
      try {
        // 将 storyId 转换为数字
        const numericStoryId = parseInt(storyId.value, 10);
        if (isNaN(numericStoryId)) {
          throw new Error('无效的故事ID');
        }

        const newFeedList = await store.dispatch('story/fetchFeedList', { 
          storyId: numericStoryId, // 使用转换后的数字格式
          refresh 
        });
        console.log('获取到的故事数据:', newFeedList);
        
        if (refresh) {
          feedItems.value = newFeedList;
        } else {
          feedItems.value = [...feedItems.value, ...newFeedList];
        }
        page.value = refresh ? 1 : page.value + 1;
      } catch (error) {
        console.error('加载故事数据失败:', error);
      } finally {
        loading.value = false;
      }
    };

    // 上拉加载更多
    const loadMore = async () => {
      if (!feedHasMore.value) return;
      await loadStoryFeeds(false);
    };

    // 下拉刷新处理
    const touchEnd = async () => {
      const distance = currentY.value - startY.value;
      if (window.scrollY === 0 && distance > 100) {
        refreshText.value = '刷新中...';
        isRefreshing.value = true;
        await loadStoryFeeds(true);
        isRefreshing.value = false;
        refreshText.value = '下拉刷新';
      }
    };

    // 监听故事ID变化
    watch(storyId, (newId, oldId) => {
      console.log('故事ID发生变化:', oldId, '->', newId);
      if (newId && newId !== oldId) {
        loadStoryFeeds(true);
      }
    });

    // 修改 initData 方法
    const initData = async () => {
      console.log('初始化数据');
      if (storyId.value) {
        await loadStoryFeeds(true);
      }
      console.log('初始数据条数：', feedItems.value.length);
    };

    // 添加滚动监听函数
    const handleScroll = () => {
      // console.log('滚动事件触发', {
      //   scrollY: window.scrollY,
      //   windowHeight: window.innerHeight,
      //   documentHeight: document.documentElement.scrollHeight
      // });
      isScrolled.value = window.scrollY > 200;
      window.dispatchEvent(new CustomEvent('update-navbar', {
        detail: {
          isScrolled: isScrolled.value,
          page: 'storyDetail'
        }
      }));
    };

    onMounted(() => {
      console.log('组件挂载完成');
      console.log('当前路由信息:', {
        fullPath: route.fullPath,
        params: route.params,
        query: route.query
      });
      parseStoryData(); // 解析数据
      initData();
      window.addEventListener('scroll', handleScroll);
      // 初始化导航栏状态
      window.dispatchEvent(new CustomEvent('update-navbar', {
        detail: {
          isScrolled: false,
          page: 'storyDetail'
        }
      }));

      // 创建观察器
      const observer = new IntersectionObserver(
        async (entries) => {
          const entry = entries[0];
          if (entry.isIntersecting && !loading.value) {
            console.log('触发加更多');
            await loadMore();
          }
        },
        {
          rootMargin: '100px', // 提前 100px 触发
          threshold: 0
        }
      );

      // 开始观察
      if (loadMoreTrigger.value) {
        observer.observe(loadMoreTrigger.value);
      }

      // 清理
      onUnmounted(() => {
        observer.disconnect();
      });
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    // 下拉刷新相关
    const touchStart = (e) => {
      startY.value = e.touches[0].pageY;
    };

    const touchMove = (e) => {
      currentY.value = e.touches[0].pageY;
      const distance = currentY.value - startY.value;
      
      if (window.scrollY === 0 && distance > 0) {
        e.preventDefault();
        if (distance > 100) {
          refreshText.value = '释放刷新';
        } else {
          refreshText.value = '下拉刷新';
        }
      }
    };

    // 解析故事数据的方法
    const parseStoryData = () => {
      console.log('=== parseStoryData 开始执行 ===');
      console.log('当前故事ID:', storyId.value);
      console.log('当前 storyObj 状态:', storyObj.value);
      
      if (route.query.storyData) {
        try {
          const parsedData = JSON.parse(route.query.storyData);
          console.log('[来源1 - 路由参数] 解析到的数据:', parsedData);
          storyObj.value = {
            ...storyObj.value,
            ...parsedData
          };
          console.log('[来源1 - 路由参数] 更新后的 storyObj:', storyObj.value);
        } catch (error) {
          console.error('[来源1 - 路由参数] 解析失败:', error);
        }
      } else if (currentStory.value) {
        if (currentStory.value.id === storyId.value) {
          console.log('[来源2 - Vuex Store] 使用 currentStory 数据:', currentStory.value);
          storyObj.value = {
            ...storyObj.value,
            ...currentStory.value
          };
          console.log('[来源2 - Vuex Store] 更新后的 storyObj:', storyObj.value);
        } else {
          console.log('[来源2 - Vuex Store] ID不匹配，需要从服务器获取数据');
          console.log('当前故事ID:', storyId.value);
          console.log('currentStory ID:', currentStory.value.id);
        }
      }
      console.log('=== parseStoryData 执行完成 ===\n');
    };

    // 添加路由监听
    watch(() => route.fullPath, (newPath, oldPath) => {
      console.log('=== 路由变化触发 ===');
      console.log('路由从', oldPath, '变化到', newPath);
      console.log('变化前 storyObj:', storyObj.value);
      parseStoryData();
      console.log('变化后 storyObj:', storyObj.value);
      console.log('=== 路由变化处理完成 ===\n');
    }, { immediate: true });

    // 监听 currentStory 变化
    watch(currentStory, (newStory, oldStory) => {
      console.log('=== currentStory 变化触发 ===');
      console.log('变化前的 story:', oldStory);
      console.log('变化后的 story:', newStory);
      if (newStory) {
        console.log('[来源3 - currentStory Watch] 更新前的 storyObj:', storyObj.value);
        storyObj.value = {
          ...storyObj.value,
          ...newStory
        };
        console.log('[来源3 - currentStory Watch] 更新后的 storyObj:', storyObj.value);
      }
      console.log('=== currentStory 变化处理完成 ===\n');
    });

    // 监听 storyId 变化
    watch(storyId, (newId, oldId) => {
      console.log('故事ID变:', oldId, '->', newId);
      if (newId !== oldId) {
        parseStoryData();
      }
    });

    // 添加判断是否显示年份和日期的方法
    const shouldShowYear = (currentItem, previousItem) => {
      if (!previousItem) return true;
      const currentYear = new Date(currentItem.feedTime).getFullYear();
      const previousYear = new Date(previousItem.feedTime).getFullYear();
      return currentYear !== previousYear;
    };

    const shouldShowDate = (currentItem, previousItem) => {
      if (!previousItem) return true;
      const currentDate = new Date(currentItem.feedTime).toDateString();
      const previousDate = new Date(previousItem.feedTime).toDateString();
      return currentDate !== previousDate;
    };

    // 处理图片查看器的打开
    const handleOpenImageViewer = (images, index) => {
      // TODO: 实现图片查看器的打开逻辑
      console.log('打开图片查看器:', { images, index });
    };

    // 打开编辑发布窗口
    const openEditPost = () => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.multiple = true;
      input.onchange = (event) => {
        const files = event.target.files;
        if (files) {
          selectedImages.value = Array.from(files).map(file => ({
            url: URL.createObjectURL(file),
            file: file,
            isUploading: true,
            uploadFailed: false
          }));
          showEditPost.value = true;
        }
      };
      input.click();
    };

    // 关闭编辑发布窗口
    const closeEditPost = () => {
      showEditPost.value = false;
      selectedImages.value = [];
    };

    // 处理发布
    const handlePublish = async (post) => {
      try {
        console.log('开始发布:', post);
        await store.dispatch('story/createFeed', {
          storyId: storyId.value,
          ...post
        });
        console.log('发布成功，准备刷新列表');
        closeEditPost();
        await loadStoryFeeds(true);
        console.log('列表刷新完成');
      } catch (error) {
        console.error('发布失败:', error);
      }
    };

    // 添加删除 feed 的方法
    const handleDeleteFeed = async (feedId) => {
      try {
        // 显示删除中的提示
        store.commit('app/showSnackbar', {
          text: '删除中...',
          color: 'info'
        });

        // 调用删除 API
        await store.dispatch('story/deleteFeed', {
          storyId: storyId.value,
          feedId: feedId
        });

        // 删除成功后更新本地数据
        const deleteIndex = feedItems.value.findIndex(item => item.id === feedId);
        if (deleteIndex > -1) {
          // 获取要删除项的前后项，用于重新计算年份显示
          const prevItem = feedItems.value[deleteIndex - 1];
          const nextItem = feedItems.value[deleteIndex + 1];

          // 删除该项
          feedItems.value.splice(deleteIndex, 1);

          // 如果删除的是某一年的第一条或最后一条数据，需要更新相邻数据的年份显示
          if (nextItem && prevItem) {
            // 检查删除后的前后项是否需要显示年份
            const shouldShowYearForNext = shouldShowYear(nextItem, prevItem);
            if (!shouldShowYearForNext) {
              // 强制更新列表以重新计算年份显示
              feedItems.value = [...feedItems.value];
            }
          }
        }

        // 显示删除成功的提示
        store.commit('app/showSnackbar', {
          text: '删除成功',
          color: 'success'
        });
      } catch (error) {
        console.error('删除失败:', error);
        // 显示删除失败的提示
        store.commit('app/showSnackbar', {
          text: '删除失败',
          color: 'error'
        });
      }
    };

    return {
      feedItems,
      loading,
      isRefreshing,
      refreshText,
      touchStart,
      touchMove,
      touchEnd,
      loadMore,
      isScrolled,
      loadMoreTrigger,
      storyObj,
      currentStory,
      feedList,
      feedHasMore,
      storyId,
      shouldShowYear,
      shouldShowDate,
      handleOpenImageViewer,
      showEditPost,
      selectedImages,
      openEditPost,
      closeEditPost,
      handlePublish,
      handleDeleteFeed,
    };
  }
}
</script>

<style scoped>
.feed {
  min-height: 100vh;
  position: relative;
  background-color: #f5f5f5; /* 改为浅灰色背景 */
}

/* 修改顶部景图区域样式 */
.feed-header {
  position: relative;
  height: 35vh; /* 减小高度 */
  min-height: 250px; /* 减小最小高度 */
  width: 100%;
  overflow: hidden;
  margin-top: -64px;
}

.header-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 64px); /* 增加高度以确保完全覆盖 */
  background-image: url('@/assets/images/feed-header.jpg');
  background-size: cover;
  background-position: center;
  transform: scale(1.1);
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.4)
  );
}

.header-content {
  position: absolute;
  bottom: 60px; /* 改为更靠的位置 */
  left: 20px;
  right: 20px;
  z-index: 1;
  color: white;
  padding-top: 64px; /* 为导航栏预留空间 */
}

.story-title {
  font-size: 24px;
  margin-bottom: 8px; /* 减小间距 */
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.story-time {
  font-size: 16px;
  margin-bottom: 4px; /* 减小间距 */
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.story-fans {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 8px; /* 减小间距 */
}

.fan-avatars {
  display: flex;
  margin-right: 10px;
}

.fan-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: -10px;
  border: 2px solid white;
}

.fans-count {
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  margin: 0;
}

.feed-content {
  position: relative;
  padding: 20px;
  margin-top: -30px; /* 减小负边距，让内区域上移 */
  background-color: #f5f5f5;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
}

.refresh-tip {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  transform: translateY(-100%);
  transition: transform 0.3s;
  z-index: 1000;
}

.refresh-tip.refreshing {
  transform: translateY(0);
}

.loading-more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  color: #666;
}

.fab-button {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 100;
}
</style>









