import authService from '@/services/authService';

export default {
  namespaced: true,
  state: {
    userInfo: null,
  },
  mutations: {
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
    },
  },
  actions: {
    async fetchUserInfo({ commit }) {
      try {
        const userInfo = await authService.getCurrentUser();
        console.log('### user.js 获取用户信息：', userInfo)
        commit('SET_USER_INFO', userInfo);
      } catch (error) {
        console.error('获取用户信息失败:', error);
        throw error;
      }
    },
  },
  getters: {
    userInfo: state => state.userInfo,
  }
};