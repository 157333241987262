<template>
  <v-dialog v-model="dialog" max-width="400px" :persistent="loading" @click:outside="closeIfNotLoading">
    <v-card class="rounded-lg">
      <v-card-title class="text-h5 font-weight-bold pa-4 pb-2">
        新建故事
      </v-card-title>
      <v-card-text class="pa-4 pt-2">
        <v-form @submit.prevent="save">
          <v-text-field
            v-model="storyName"
            label="故事本名字"
            placeholder="例如：我的猫猫传奇"
            variant="outlined"
            dense
            class="mb-4"
          ></v-text-field>
          <v-text-field
            v-model="protagonistName"
            label="主角名字"
            placeholder="例如：猫猫名字"
            variant="outlined"
            dense
            class="mb-4"
          ></v-text-field>
          <p class="mb-2">故事时间</p>
          <div class="d-flex align-center mb-2">
            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="formattedStartDate"
                  label="开始时间"
                  readonly
                  dense
                  outlined
                  class="mr-2"
                  @click="startDateMenu = true"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="storyStartDate"
                @input="startDateSelected"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="startDateMenu = false">
                  取消
                </v-btn>
                <v-btn text color="primary" @click="startDateSelected(storyStartDate)">
                  确定
                </v-btn>
              </v-date-picker>
            </v-menu>
            <span class="mx-2">-</span>
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="formattedEndDate"
                  label="结束时间"
                  readonly
                  dense
                  outlined
                  @click="endDateMenu = true"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="storyEndDate"
                @input="endDateSelected"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="setEndDateToPresent">
                  至今
                </v-btn>
                <v-btn text color="primary" @click="endDateMenu = false">
                  取消
                </v-btn>
                <v-btn text color="primary" @click="endDateSelected(storyEndDate)">
                  确定
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
          <p class="text-caption mb-2">* 会计算某一个每一天</p>
          <p v-if="timeAgo" class="text-caption">{{ timeAgo }}</p>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-4 pt-0">
        <v-spacer></v-spacer>
        <v-btn color="grey-darken-1" variant="text" @click="closeIfNotLoading" :disabled="loading">取消</v-btn>
        <v-btn
          color="primary"
          @click="save"
          :disabled="!isFormValid || loading"
          :loading="loading"
          class="create-btn"
        >
          创建
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { eventBus } from '@/utils/eventBus';
import { getTimeAgoString } from '@/utils/dateUtils';

export default {
  name: 'NewStoryModal',
  props: {
    modelValue: Boolean
  },
  data() {
    return {
      storyName: '',
      protagonistName: '',
      storyStartDate: '',
      storyEndDate: null,
      startDateMenu: false,
      endDateMenu: false,
      loading: false,
      timeAgo: ''
    }
  },
  computed: {
    dialog: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    isFormValid() {
      return this.storyName && this.protagonistName && this.storyStartDate
    },
    formattedStartDate() {
      return this.formatDate(this.storyStartDate);
    },
    formattedEndDate() {
      return this.storyEndDate ? this.formatDate(this.storyEndDate) : '至今';
    }
  },
  methods: {
    ...mapActions('story', ['createStory']),
    closeIfNotLoading() {
      if (!this.loading) {
        this.close();
      }
    },
    close() {
      this.dialog = false;
      this.$emit('close');
      this.resetForm();
    },
    async save() {
      if (this.isFormValid) {
        this.loading = true;
        try {
          const newStory = await this.createStory({
            name: this.storyName,
            protagonistName: this.protagonistName,
            startDate: this.storyStartDate,
            endDate: this.storyEndDate
          });
          
          eventBus.emit('show-snackbar', '故事本创建成功');
          this.$emit('story-created', newStory);
          this.$router.push({ name: 'StoryDetail', params: { id: newStory.id } });
        } catch (error) {
          eventBus.emit('show-snackbar', '故事本创建失败：' + error.message);
        } finally {
          this.loading = false;
          this.close();
        }
      }
    },
    resetForm() {
      this.storyName = '';
      this.protagonistName = '';
      this.storyStartDate = '';
      this.storyEndDate = null;
      this.timeAgo = '';
    },
    updateTimeAgo() {
      if (this.storyStartDate) {
        const startDate = new Date(this.storyStartDate);
        const endDate = this.storyEndDate ? new Date(this.storyEndDate) : 0;
        this.timeAgo = getTimeAgoString(startDate, endDate);
      } else {
        this.timeAgo = '';
      }
    },
    setEndDateToPresent() {
      this.storyEndDate = null;
      this.endDateMenu = false;
      this.updateTimeAgo();
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}/${month}/${day}`;
    },
    startDateSelected(date) {
      this.storyStartDate = date;
      this.startDateMenu = false;
      this.updateTimeAgo();
    },
    endDateSelected(date) {
      this.storyEndDate = date;
      this.endDateMenu = false;
      this.updateTimeAgo();
    }
  }
}
</script>

<style scoped>
.create-btn {
  min-width: 90px;
  font-weight: 600;
}
</style>
