// 从 Vue 框架中导入创建应用的函数
// Vue：这是整个应用的核心框架，用于构建用户界面。
import { createApp } from 'vue';
// 导入根组件 App
// App.vue：这是应用的根组件，所有其他组件都会嵌套在这个组件内。
import App from './App.vue';

// 导入路由配置，用于管理页面跳转
// router：这个库用于管理页面之间的跳转，就像网站的目录一样。
import router from './router';

// 导入状态管理库，用于集中管理应用的数据
// store：这是一个集中管理数据的工具，可以想象成应用的"中央数据库"。
import store from './store';

// 导入 Vuetify 插件，这是一个 UI 组件库，提供了许多现成的界面元素
// vuetify：这是一个 UI 组件库，提供了许多漂亮的现成组件，比如按钮、表单等，可以让开发者快速构建美观的界面。
import vuetify from './plugins/vuetify';

// 导入 Vuetify 的基础样式
// Vuetify 样式：这是 Vuetify 组件库的基础样式表，确保组件能正确显示。
import 'vuetify/styles';

// 导入 Material Design 图标库的样式，提供了大量的图标供使用
// Material Design 图标：这是一套广泛使用的图标库，提供了大量的图标供应用使用。
import '@mdi/font/css/materialdesignicons.css';

// 导入 LoginRegisterModal 组件
import LoginRegisterModal from '@/components/modals/LoginRegisterModal.vue';

import infiniteScroll from 'vue-infinite-scroll'

const app = createApp(App);

app.component('LoginRegisterModal', LoginRegisterModal);

app.use(router);
app.use(store);
app.use(vuetify);
app.use(infiniteScroll);

app.mount('#app');
