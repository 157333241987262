<template>
  <v-card 
    class="carousel-card"
    :class="{ 
      'active': isActive,
      'prev': isPrev,
      'next': isNext
    }"
    elevation="4"
    rounded="lg"
  >
    <div class="image-container">
      <v-img
        :src="cardData.image"
        cover
      ></v-img>
    </div>
    <v-card-text class="text-left pa-4">
      <div class="text-h6 mb-2">{{ cardData.title }}</div>
      <!-- 日期部分 -->
      <div class="text-body-2 text-grey mb-3">
        <div class="d-flex align-center">
          <v-icon small class="mr-1">mdi-calendar</v-icon>
          <span>在{{ cardData.date }}这一天</span>
        </div>
      </div>
      <!-- 年龄信息部分 -->
      <div class="age-info">
        <!-- 我的年龄 -->
        <div class="age-row mb-2">
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex align-center">
              <v-icon small color="primary" class="mr-1">mdi-account</v-icon>
              <span class="text-body-2 text-medium-emphasis">我的年龄</span>
            </div>
            <span class="age-value">{{ cardData.humanAge }}</span>
          </div>
        </div>
        <!-- 宠物年龄 -->
        <div class="age-row">
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex align-center">
              <v-icon small color="amber" class="mr-1">mdi-cat</v-icon>
              <span class="text-body-2 text-medium-emphasis">{{ cardData.petName }}的年龄</span>
            </div>
            <span class="age-value">{{ cardData.petAge }}</span>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PetAgeCard',
  props: {
    cardData: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    },
    isPrev: {
      type: Boolean,
      default: false
    },
    isNext: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
/* 卡片基础样式 */
.carousel-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 478px;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  visibility: hidden;
  opacity: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: white;
}

/* 当前卡片样式 */
.carousel-card.active {
  visibility: visible;
  opacity: 1;
  transform: translateX(0) translateY(0) scale(1);
  z-index: 3;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* 上一张卡片样式 */
.carousel-card.prev {
  visibility: visible;
  opacity: 0.5;
  transform: translateX(-15%) translateY(20px) scale(0.85);
  z-index: 2;
}

/* 下一张卡片样式 */
.carousel-card.next {
  visibility: visible;
  opacity: 0.5;
  transform: translateX(15%) translateY(20px) scale(0.85);
  z-index: 2;
}

/* 图片容器样式 */
.image-container {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

/* 修改图片样式 */
.v-img {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

/* 卡片文字内容区域 */
.v-card-text {
  padding: 16px !important;
  background: white;
  height: auto;
}

/* 年龄信息样式 */
.age-info {
  padding-top: 4px;
}

.age-row {
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  padding: 8px 12px;
  margin-bottom: 8px;
}

.age-row:last-child {
  margin-bottom: 0;
}

.age-value {
  font-size: 1rem !important;
  font-weight: 500;
  color: var(--text-primary);
  margin-left: 12px;
}

/* 标题样式 */
.text-h6 {
  font-size: 1.25rem !important;
  font-weight: 600;
  color: var(--text-primary);
  line-height: 1.4;
}

/* 日期样式 */
.text-body-2 {
  color: var(--text-secondary);
}

/* 悬停效果 */
.carousel-card.active:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1) !important;
}

/* 移动端适配 */
@media (max-width: 959px) {
  .carousel-card {
    height: 468px;
  }
  
  .age-value {
    font-size: 0.875rem !important;
  }
  
  .age-row {
    padding: 6px 10px;
  }
  
  .text-h6 {
    font-size: 1.125rem !important;
  }
}
</style> 