<template>
  <v-dialog v-model="dialog" max-width="400px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ isLogin ? '登录' : '注册' }}</span>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="submitForm">
          <v-text-field
            v-model="email"
            label="邮箱"
            required
            :rules="[v => !!v || '邮箱是必填的', v => /.+@.+\..+/.test(v) || '请输入有效的邮箱地址']"
          ></v-text-field>
          <v-text-field
            v-model="password"
            label="密码"
            type="password"
            required
            :rules="[v => !!v || '密码是必填的', v => v.length >= 6 || '密码至少需要6个字符']"
          ></v-text-field>
          <v-btn 
            color="primary" 
            block 
            type="submit"
            :loading="loading"
          >
            {{ isLogin ? '登录' : '注册' }}
          </v-btn>
        </v-form>
        <div class="mt-4 text-center">
          <a href="#" @click.prevent="toggleMode">
            {{ isLogin ? '没有账号?立即注册' : '已有账号?立即登录' }}
          </a>
        </div>
        <div v-if="isLogin" class="mt-2 text-center">
          <a href="#" @click.prevent="forgotPassword">忘记密码?</a>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- Toast 提示 -->
  <v-snackbar v-model="showToast" :color="toastColor" :timeout="3000">
    {{ toastMessage }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="showToast = false">
        关闭
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'LoginRegisterModal',
  props: {
    modelValue: Boolean,
    mode: { 
      type: String,
      default: 'login',
      validator: (value) => ['login', 'register'].includes(value)
    }
  },
  emits: ['update:modelValue', 'login-success'],
  setup(props, { emit }) {
    const store = useStore();
    const dialog = ref(props.modelValue);
    const isLogin = ref(props.mode === 'login');
    const email = ref('');
    const password = ref('');
    const loading = ref(false); 
    const showToast = ref(false);
    const toastMessage = ref('');
    const toastColor = ref('success');

    watch(() => props.modelValue, (newValue) => {
      dialog.value = newValue;
      console.log('### LoginRegisterModal.vue 弹窗打开：', newValue)
      if (newValue) {
        resetForm();
      }
    });

    watch(() => props.mode, (newValue) => {
      console.log('Mode changed to:', newValue);
      isLogin.value = newValue === 'login';
      resetForm();
    });

    watch(dialog, (newValue) => {
      emit('update:modelValue', newValue);
    });

    const resetForm = () => {
      email.value = '';
      password.value = '';
      isLogin.value = props.mode === 'login';
    };

    const toggleMode = () => {
      isLogin.value = !isLogin.value;
      email.value = '';
      password.value = '';
    };

    const showToastMessage = (message, color = 'success') => {
      toastMessage.value = message;
      toastColor.value = color;
      showToast.value = true;
    };

    const submitForm = async () => {
      loading.value = true;
      try {
        let response;
        if (isLogin.value) {
          response = await store.dispatch('auth/login', { email: email.value, password: password.value });
        } else {
          response = await store.dispatch('auth/register', { email: email.value, password: password.value });
        }
        
        if (response.result === 0) {
          showToastMessage(isLogin.value ? '登录成功' : '注册成功');
          dialog.value = false;
          console.log('触发登录成功事件，准备打开编辑弹窗');
          emit('login-success', { openEditPost: true });
        } else {
          throw new Error(response.message || '操作失败');
        }
      } catch (error) {
        console.error('操作失败:', error.message);
        showToastMessage(error.message || '操作失败，请稍后重试', 'error');
      } finally {
        loading.value = false;
      }
    };

    const forgotPassword = () => {
      console.log('忘记密码');
      showToastMessage('忘记密码功能暂未实现', 'info');
    };

    return {
      dialog,
      isLogin,
      email,
      password,
      loading,
      showToast,
      toastMessage,
      toastColor,
      toggleMode,
      submitForm,
      forgotPassword,
    };
  },
};
</script>
