import axios from 'axios';
import router from '@/router'; // 确保导入了 router
import store from '@/store'; // 确保导入了 store

// 创建 Axios 实例
const api = axios.create({
  baseURL: 'http://www.mymemoir.me/comory', // API 的基础 URL，所有请求都会基于这个 URL
  timeout: 10000, // 请求超时时间，单位为毫秒
  headers: {
    'Content-Type': 'application/json', // 设置默认的请求头
  },
  withCredentials: true // 允许跨域请求携带凭证（如 cookies）
});

// 请求拦截器
api.interceptors.request.use(
  (config) => {
    console.log('### api.js 发起请求:', config);

    // 在发送请求之前执行的操作
    const token = localStorage.getItem('token'); // 从本地存储获取 token
    if (token) {
      // 如果 token 存在，将其添加到请求头中
      config.headers['Authorization'] = token;
    }
    return config; // 返回修改后的配置
  },
  (error) => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

// 响应拦截器
api.interceptors.response.use(
  (response) => {
    // 处理响应数据
    console.log('### api.js 请求成功:', response.data);
    return response.data; // 直接返回响应的数据部分
  },
  (error) => {
    // 处理响应错误
    if (error.response) {
      // 服务器返回了错误状态码
      console.error('Response error:', error.response.data);
      if (error.response.data.result === -10) {
        console.log('### api.js 【result=-10 登录已过期】');
        if (router.currentRoute.value.name !== 'Home') {
          router.push({ name: 'Home' });
        }
        store.commit('auth/CLEAR_AUTH');
      }
    } else if (error.request) {
      // 请求已发出，但没有收到响应
      console.error('No response received:', error.request);
    } else {
      // 设置请求时发生错误
      console.error('Request error:', error.message);
    }
    return Promise.reject(error);
  }
);

// 获取故事本详情列表的 API 函数
export const getFeedList = (pageNo = 1, pageSize = 10) => {
  return api.post('/feed/list', { pageNo, pageSize });
};

export default api;