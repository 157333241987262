import { reactive } from 'vue'

export const eventBus = reactive({
  on(event, callback) {
    this[event] = this[event] || []
    this[event].push(callback)
  },
  emit(event, ...args) {
    if (this[event]) {
      this[event].forEach(callback => callback(...args))
    }
  },
  off(event, callback) {
    if (this[event]) {
      this[event] = this[event].filter(cb => cb !== callback)
    }
  }
})