import authService from '@/services/authService';
import router from '@/router'; // 确保导入了 router

export default {
  namespaced: true, // 启用命名空间，避免多个模块间的命名冲突
  state: {
    token: localStorage.getItem('token') || null,// 从本地存储获取 token，如果没有则为 null
    isAuthenticated: !!localStorage.getItem('token')// 根据 token 是否存在来判断用户是否已认证
  },
  mutations: {
    // 设置 token 并保存到本地存储
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    // 更新认证状态
    SET_AUTH(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    // 清除所有认证相关信息
    CLEAR_AUTH(state) {
      state.token = null;
      state.isAuthenticated = false;
      localStorage.removeItem('token');
    }
  },


  actions: {
    // 登录操作
    async login({ commit }, credentials) {
      try {
        const response = await authService.login(credentials.email, credentials.password);
        commit('SET_TOKEN', response.body.token);
        commit('SET_AUTH', true);
        console.log('### auth.js 登录成功',response);
        
        // 直接更新故事列表
        if (response.body && Array.isArray(response.body.storyList)) {
          commit('story/SET_STORY_LIST', response.body.storyList, { root: true });
          console.log('### auth.js 故事列表已更新');
        }
        
        return response;
      } catch (error) {
        console.error('登录失败:', error);
        throw error;
      }
    },

    // 注册操作
    async register({ commit }, { email, password }) {
      try {
        const response = await authService.register(email, password);
        commit('SET_TOKEN', response.body.token);
        commit('SET_AUTH', true);
        
        // 直接更新故事列表
        if (response.body && Array.isArray(response.body.storyList)) {
          commit('story/SET_STORY_LIST', response.body.storyList, { root: true });
          console.log('### auth.js 注册成功，故事列表已更新');
        }
        
        return response;
      } catch (error) {
        console.error('注册失败:', error);
        throw error;
      }
    },
    // 注销操作 
    logout({ commit }) {
      authService.logout();
      commit('CLEAR_AUTH');
      commit('user/SET_USER_INFO', null, { root: true });
      commit('story/SET_STORY_LIST', [], { root: true }); // 直接传递空数组
    },

    // 检查认证状态
    async checkAuth({ commit, dispatch, state }) {
      console.log('### auth.js 检查有没有登录注册的缓存：', state.token)
      if (!state.token  || state.token === 'undefined' || state.token === undefined) {
        console.log('### auth.js 【重新登录 没有 token】');
        if (router.currentRoute.value.name !== 'Home') {
          router.push({ name: 'Home' });
        }
        commit('CLEAR_AUTH');
        return; 
      }

      try {
        // await dispatch('user/fetchUserInfo', null, { root: true });
        console.log('### auth.js 登录成功')
        await dispatch('story/fetchStoryList', null, { root: true });
        
        commit('SET_AUTH', true);
      } catch (error) {
        console.error('Token 验证失败:', error);
        console.log('### auth.js 【登录已过期】');
        if (router.currentRoute.value.name !== 'Home') {
          router.push({ name: 'Home' });
        }
        commit('CLEAR_AUTH');
      }
    },
  },
  getters: {
    // 获取认证状态
    isAuthenticated: state => state.isAuthenticated
  }
};