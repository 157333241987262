<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="feed" 
    @touchstart="touchStart"
    @touchmove="touchMove"
    @touchend="touchEnd"
  >
    <!-- 下拉刷新提示 -->
    <div class="refresh-tip" :class="{ 'refreshing': isRefreshing }">
      {{ refreshText }}
    </div>

    <!-- 顶部背景图区域保持不变 -->
    <div class="feed-header">
      <div class="header-image"></div>
      <div class="header-overlay"></div>
      <div class="header-content">
        <!-- <h1>我的动态</h1> -->
      </div>
    </div>

    <div class="feed-content">
      <!-- 添加多个内容卡片 -->
      <div v-for="(item, index) in feedItems" :key="index" class="content-card">
        <div class="card-header">
          <v-avatar size="40" color="grey" class="mr-3">
            <img :src="item.avatar" alt="avatar">
          </v-avatar>
          <div class="user-info">
            <div class="username">{{ item.username }}</div>
            <div class="post-time">{{ item.time }}</div>
          </div>
        </div>
        
        <div class="card-content">
          <p class="content-text">{{ item.content }}</p>
          
          <!-- 图片网格 -->
          <div class="image-grid">
            <div v-for="(img, imgIndex) in item.images" :key="imgIndex" class="image-item">
              <v-img
                :src="img"
                aspect-ratio="1"
                cover
              ></v-img>
            </div>
          </div>
        </div>

        <div class="card-actions">
          <v-btn icon variant="text">
            <v-icon>mdi-heart-outline</v-icon>
            <span class="ml-1">{{ item.likes }}</span>
          </v-btn>
          <v-btn icon variant="text">
            <v-icon>mdi-comment-outline</v-icon>
            <span class="ml-1">{{ item.comments }}</span>
          </v-btn>
        </div>
      </div>

      <!-- 加载更多触发器 -->
      <div 
        ref="loadMoreTrigger"
        class="loading-more"
      >
        <template v-if="loading">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <span class="ml-2">加载中...</span>
        </template>
      </div>
    </div>

    <!-- 新增：显示指引弹窗的按钮 -->
    <button class="guide-button" @click="showGuide = true">显示新手指引</button>

    <!-- 新手指引弹窗 -->
    <GuideOverlay 
      :show="showGuide" 
      @close="closeGuide"
      @start="handleStart"
    />

    <!-- 添加 EditPost 组件 -->
    <EditPost
      v-if="showEditPost"
      :images="[]"
      @close="closeEditPost"
      @publish="handlePublish"
    />

    <!-- 登录注册弹窗 -->
    <LoginRegisterModal
      v-model="showLoginModal"
      :mode="loginMode"
      @login-success="handleLoginSuccess"
    />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import GuideOverlay from '@/components/GuideOverlay.vue';
import EditPost from '@/views/EditPost.vue';
import LoginRegisterModal from '@/components/modals/LoginRegisterModal.vue';

// 生成假数据的辅助函数
const generateFakeItem = (index) => ({
  id: Date.now() + index,
  username: `用户名称 ${index}`,
  avatar: 'https://cdn.vuetifyjs.com/images/john.jpg',
  time: '2小时前',
  content: `这是第 ${index} 条动态内容。这里是一些示例文字，描述用户分享的故事和心情。`,
  images: Array(3).fill(0).map((_, i) => 
    `https://picsum.photos/500/300?random=${index*3 + i}`
  ),
  likes: Math.floor(Math.random() * 100),
  comments: Math.floor(Math.random() * 50)
});

export default {
  name: 'Feed',
  components: {
    GuideOverlay,
    EditPost,
    LoginRegisterModal,
  },
  setup() {
    const feedItems = ref([]);
    const loading = ref(false);
    const isRefreshing = ref(false);
    const refreshText = ref('下拉刷新');
    const startY = ref(0);
    const currentY = ref(0);
    const page = ref(1);
    const showGuide = ref(false);
    const albumId = ref(null);
    const isScrolled = ref(false);
    const loadMoreTrigger = ref(null);
    const showEditPost = ref(false);
    const showLoginModal = ref(false);
    const loginMode = ref('login');

    // 初始化数据
    const initData = () => {
      console.log('初始化数据');
      const initialItems = Array(10).fill(0).map((_, index) => 
        generateFakeItem(index + 1)
      );
      feedItems.value = initialItems;
      console.log('初始数据条数：', feedItems.value.length);
    };

    // 添加滚动监听函数
    const handleScroll = () => {
      console.log('滚动事件触发', {
        scrollY: window.scrollY,
        windowHeight: window.innerHeight,
        documentHeight: document.documentElement.scrollHeight
      });
      isScrolled.value = window.scrollY > 200;
      window.dispatchEvent(new CustomEvent('update-navbar', {
        detail: {
          isScrolled: isScrolled.value,
          page: 'feed'
        }
      }));
    };

    onMounted(() => {
      console.log('组件挂载完成');
      initData();
      window.addEventListener('scroll', handleScroll);
      // 初始化导航栏状态
      window.dispatchEvent(new CustomEvent('update-navbar', {
        detail: {
          isScrolled: false,
          page: 'feed'
        }
      }));

      // 创建观察器
      const observer = new IntersectionObserver(
        async (entries) => {
          const entry = entries[0];
          if (entry.isIntersecting && !loading.value) {
            console.log('触发加载更多');
            await loadMore();
          }
        },
        {
          rootMargin: '100px', // 提前 100px 触发
          threshold: 0
        }
      );

      // 开始观察
      if (loadMoreTrigger.value) {
        observer.observe(loadMoreTrigger.value);
      }

      // 清理
      onUnmounted(() => {
        observer.disconnect();
      });
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    const closeGuide = () => {
      showGuide.value = false;
    };

    const handleStart = (data) => {
      console.log('Selected date in Feed component:', data);
      console.log('New story created with ID:', data.storyId);
      // 这里可以处理新创建的故事，比如更新 UI 或跳转到新故事页面
    };

    // 下拉刷新相关
    const touchStart = (e) => {
      startY.value = e.touches[0].pageY;
    };

    const touchMove = (e) => {
      currentY.value = e.touches[0].pageY;
      const distance = currentY.value - startY.value;
      
      if (window.scrollY === 0 && distance > 0) {
        e.preventDefault();
        if (distance > 100) {
          refreshText.value = '释放刷新';
        } else {
          refreshText.value = '下拉刷新';
        }
      }
    };

    const touchEnd = async () => {
      const distance = currentY.value - startY.value;
      if (window.scrollY === 0 && distance > 100) {
        refreshText.value = '刷新中...';
        isRefreshing.value = true;
        
        // 模拟网络请求
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // 添加新数据到顶部
        const newItems = Array(5).fill(0).map((_, index) => 
          generateFakeItem(index + 1)
        );
        feedItems.value = [...newItems, ...feedItems.value];
        
        isRefreshing.value = false;
        refreshText.value = '下拉刷新';
      }
    };

    // 上拉加载更多
    const loadMore = async () => {
      console.log('loadMore 函数被调用');
      console.log('当前状态：', {
        loading: loading.value,
        feedItemsCount: feedItems.value.length,
        scrollPosition: window.scrollY,
        windowHeight: window.innerHeight,
        documentHeight: document.documentElement.scrollHeight
      });

      if (loading.value) {
        console.log('正在加载中，跳过此次加载请求');
        return;
      }
      
      console.log('触发上拉加载更多，当前页码：', page.value);
      loading.value = true;
      
      // 模拟网络请求
      console.log('开始加载新数据...');
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const newItems = Array(5).fill(0).map((_, index) => 
        generateFakeItem(page.value * 10 + index + 1)
      );
      console.log('生成新数据：', newItems.length, '条');
      
      feedItems.value = [...feedItems.value, ...newItems];
      page.value++;
      loading.value = false;
      console.log('加载完成，当前总条数：', feedItems.value.length);
    };

    // 处理登录成功事件
    const handleLoginSuccess = (data) => {
      console.log('登录成功，准备打开编辑弹窗', data);
      if (data?.openEditPost) {
        showEditPost.value = true;
      }
    };

    const closeEditPost = () => {
      showEditPost.value = false;
    };

    const handlePublish = () => {
      showEditPost.value = false;
      // 其他发布后的逻辑...
    };

    return {
      feedItems,
      loading,
      isRefreshing,
      refreshText,
      touchStart,
      touchMove,
      touchEnd,
      loadMore,
      showGuide,
      closeGuide,
      albumId,
      handleStart,
      isScrolled,
      loadMoreTrigger,
      showEditPost,
      handleLoginSuccess,
      closeEditPost,
      handlePublish,
      showLoginModal,
      loginMode,
    };
  }
}
</script>

<style scoped>
.feed {
  min-height: 100vh;
  position: relative;
  background-color: #f5f5f5; /* 改为浅灰色背景 */
}

/* 修改顶部背景图区域样式 */
.feed-header {
  position: relative;
  height: 40vh;
  min-height: 300px;
  width: 100%;
  overflow: hidden;
  margin-top: -64px; /* 调整为完全覆盖导航栏区域，包括可能的边距 */
}

.header-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 64px); /* 增加高度以确保完全覆盖 */
  background-image: url('@/assets/images/feed-header.jpg');
  background-size: cover;
  background-position: center;
  transform: scale(1.1);
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.4)
  );
}

.header-content {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  z-index: 1;
}

.header-content h1 {
  color: white;
  font-size: 28px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  margin: 0;
}

.feed-content {
  position: relative;
  padding: 20px;
  margin-top: -50px; /* 使内容区域略微覆盖在背景图上 */
  background-color: #f5f5f5; /* 与 feed 背景色保持一致 */
  border-radius: 20px 20px 0 0; /* 添加圆角 */
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
  /* 移除 min-height: 200vh */
}

.content-card {
  background: white; /* 保持卡片为纯白色 */
  border-radius: 12px; /* 增加圆角 */
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08); /* 增强阴影效果 */
  border: 1px solid rgba(0, 0, 0, 0.05); /* 添加微妙的边框 */
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.user-info {
  flex: 1;
}

.username {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.post-time {
  font-size: 12px;
  color: #666;
  margin-top: 2px;
}

.content-text {
  margin-bottom: 12px;
  line-height: 1.5;
  color: #333;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
  margin-bottom: 12px;
}

.image-item {
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 4px;
}

.card-actions {
  display: flex;
  gap: 16px;
  padding-top: 8px;
  border-top: 1px solid #eee;
}

.v-btn {
  color: #666;
}

.guide-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
}

.guide-button:hover {
  background-color: #0056b3;
}

p {
  color: #333;
  margin-bottom: 15px;
}

.refresh-tip {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  transform: translateY(-100%);
  transition: transform 0.3s;
  z-index: 1000;
}

.refresh-tip.refreshing {
  transform: translateY(0);
}

.loading-more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  color: #666;
}
</style>






