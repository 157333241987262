<template>
  <div class="user-profile">
    <h1>用户个人页面</h1>
    <p>用户名: {{ $route.params.username }}</p>
    <!-- 这里可以添加更多用户信息和内容 -->
  </div>
</template>

<script>
export default {
  name: 'UserProfile',
  // 如果需要，可以在这里添加更多逻辑
}
</script>

<style scoped>
/* 可以添加样式 */
</style>