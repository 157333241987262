import storyApi from '@/services/storyApi';

export default {
  namespaced: true,
  state: {
    currentStory: null,
    storyList: [],
    feedList: [],
    feedPageNo: 1,
    feedHasMore: true,
    feedTotal: 0,
  },
  mutations: {
    SET_CURRENT_STORY(state, story) {
      state.currentStory = story;
    },
    SET_STORY_LIST(state, list) {
      state.storyList = Array.isArray(list) ? list : [];
    },
    ADD_STORY(state, story) {
      state.storyList.push(story);
    },
    REMOVE_STORY(state, storyId) {
      state.storyList = state.storyList.filter(story => story.id !== storyId);
    },
    SET_FEED_LIST(state, list) {
      state.feedList = list;
    },
    APPEND_FEED_LIST(state, list) {
      state.feedList = [...state.feedList, ...list];
    },
    INCREMENT_FEED_PAGE(state) {
      state.feedPageNo++;
    },
    SET_FEED_HAS_MORE(state, hasMore) {
      state.feedHasMore = hasMore;
    },
    SET_FEED_PAGE(state, pageNo) {
      state.feedPageNo = pageNo;
    },
    SET_FEED_TOTAL(state, total) {
      state.feedTotal = total;
    },
  },
  actions: {
    async fetchStoryDetails({ commit }, storyId) {
      try {
        const response = await storyApi.getStoryDetails(storyId);
        commit('SET_CURRENT_STORY', response.data);
        return response.data;
      } catch (error) {
        console.error('获取故事详情失败:', error);
        throw error;
      }
    },
    async fetchStoryList({ commit }) {
      console.log('### story.js fetchStoryList 开始执行');
      try {
        const response = await storyApi.getStoryList();
        if (response.result === 0 && Array.isArray(response.body.list)) {
          commit('SET_STORY_LIST', response.body.list);
          console.log('### story.js 获取故事列表：', response.body.list)
          return response.body.list;
        } else {
          console.error('获取故事列表失败:', response);
          commit('SET_STORY_LIST', []);
        }
      } catch (error) {
        console.error('获取故事列表出错:', error);
        commit('SET_STORY_LIST', []);
      }
      console.log('### story.js fetchStoryList 执行完成');
    },
    async createStory({ commit }, storyData) {
      try {
        const response = await storyApi.createStory(storyData);
        if (response.result === 0) {
          commit('ADD_STORY', response.body);
          console.log('### story.js 新建故事成功：', response);
          return response.body;
        } else {
          console.error('新建故事失败:', response);
          throw new Error(response.message || '新建故事失败');
        }
      } catch (error) {
        console.error('新建故事出错:', error);
        throw error;
      }
    },
    setCurrentStory({ commit }, story) {
      commit('SET_CURRENT_STORY', story);
    },
    async deleteStory({ commit }, storyId) {
      try {
        const response = await storyApi.deleteStory(storyId);
        if (response.result === 0) {
          commit('REMOVE_STORY', storyId);
          return true;
        } else {
          throw new Error(response.message || '删除故事失败');
        }
      } catch (error) {
        console.error('删除故事出错:', error);
        throw error;
      }
    },
    async fetchFeedList({ commit, state }, { storyId, refresh = false }) {
      try {
        const pageNo = refresh ? 1 : state.feedPageNo;
        console.log('### fetchFeedList 拉取 feed 列表',state)
        const response = await storyApi.getFeedList(storyId, pageNo);
        if (response.result === 0 && Array.isArray(response.body.list)) {
          const feedList = response.body.list;
          if (refresh) {
            commit('SET_FEED_LIST', feedList);
            commit('SET_FEED_PAGE', 1);
          } else {
            commit('APPEND_FEED_LIST', feedList);
            commit('INCREMENT_FEED_PAGE');
          }
          commit('SET_FEED_TOTAL', response.body.total);
          commit('SET_FEED_HAS_MORE', state.feedList.length < response.body.total);
          return feedList;
        } else {
          console.error('获取 feed 列表失败:', response);
          return [];
        }
      } catch (error) {
        console.error('获取 feed 列表出错:', error);
        return [];
      }
    },
    async commitFeed({ dispatch }, feedData) {
      try {
        const response = await storyApi.commitFeed(feedData);
        if (response.result === 0) {
          // 发布成功后刷新 feed 列表
          await dispatch('fetchFeedList', { storyId: feedData.storyId, refresh: true });
          return true;
        } else {
          throw new Error(response.message || '发布失败');
        }
      } catch (error) {
        console.error('发布 feed 出错:', error);
        throw error;
      }
    },
    async deleteFeed({ dispatch, state }, feedId) {
      try {
        const response = await storyApi.deleteFeed(feedId);
        if (response.result === 0) {
          // 删除成功后刷新 feed 列表
          await dispatch('fetchFeedList', { storyId: state.currentStory.id, refresh: true });
          return true;
        } else {
          throw new Error(response.message || '删除失败');
        }
      } catch (error) {
        console.error('删除 feed 出错:', error);
        throw error;
      }
    },
  },
  getters: {
    currentStory: state => state.currentStory,
    storyList: state => state.storyList,
    feedList: state => state.feedList,
    feedHasMore: state => state.feedHasMore,
    feedTotal: state => state.feedTotal,
  },
};
