<template>
  <div class="stories">
    <v-main>
      <v-container class="pt-0">
        <v-row>
          <v-col v-for="story in storyList" :key="story.id" cols="12" sm="6" md="4">
            <v-card @click="viewStory(story.id)" hover>
              <v-card-title>{{ story.title }}</v-card-title>
              <v-card-subtitle>{{ story.leadRole }}</v-card-subtitle>
              <v-card-text>
                <p>跨度: {{ getTimeAgo(story.startTime, story.endTime) }}</p>
                <p>时间：{{ formatTimeRange(story.startTime, story.endTime) }}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <NewStoryModal 
      v-model="showNewStoryModal" 
      @story-created="handleStoryCreated"
      @update:modelValue="updateShowNewStoryModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getTimeAgoString } from '@/utils/dateUtils';
import NewStoryModal from '@/components/modals/NewStoryModal.vue';

export default {
  name: 'StoriesPage',
  components: {
    NewStoryModal
  },
  data: () => ({
    showNewStoryModal: false
  }),
  computed: {
    ...mapState('story', ['storyList'])
  },
  methods: {
    ...mapActions('story', ['fetchStoryList']),
    formatTimeRange(startTime, endTime) {
      const start = this.formatDateSimple(startTime);
      const end = endTime ? this.formatDateSimple(endTime) : '至今';
      return `${start} - ${end}`;
    },
    formatDateSimple(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}/${month}/${day}`;
    },
    getTimeAgo(startTime, endTime) {
      const start = new Date(startTime);
      const end = endTime ? new Date(endTime) : 0;
      return getTimeAgoString(start, end);
    },
    viewStory(id) {
      this.$router.push({ name: 'StoryDetail', params: { id } });
    },
    openNewStoryModal() {
      this.showNewStoryModal = true;
    },
    async handleStoryCreated() {
      await this.fetchStoryList();
    },
    updateShowNewStoryModal(value) {
      this.showNewStoryModal = value;
    }
  },
  async created() {
    await this.fetchStoryList();
  },
  mounted() {
    this.$root.$on('open-new-story-modal', this.openNewStoryModal);
  },
  beforeUnmount() {
    this.$root.$off('open-new-story-modal', this.openNewStoryModal);
  }
};
</script>

<style scoped>
.stories {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
