<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
    content-class="dialog-transition"
  >
    <v-card class="edit-post-card">
      <v-card-text class="pa-0 main-container" style="margin-bottom: 80px; overflow-y: auto;">
        <v-container fluid class="pa-4">
          <!-- 合并输入框、日期和图片模块 -->
          <div class="module-wrapper">
            <div class="white-module">
              <!-- 日期区域 -->
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                location="bottom"
                :offset="0"
                min-width="auto"
              >
                <template v-slot:activator="{ props }">
                  <div class="date-display" v-bind="props">
                    <v-icon class="date-icon" size="28" color="rgba(0, 0, 0, 0.87)">mdi-calendar</v-icon>
                    <span class="date-text">{{ formatDate(date) }}</span>
                  </div>
                </template>

                <v-date-picker
                  v-model="date"
                  @update:model-value="dateMenu = false"
                  :max="new Date().toISOString().substr(0, 10)"
                ></v-date-picker>
              </v-menu>

              <!-- 图片区域 -->
              <div v-if="localImages.length > 0" class="image-container">
                <img 
                  :src="localImages[0].url" 
                  :class="['single-preview-img', { 'crop': singleImageAspectRatio < 3/4 }]" 
                  @load="onSingleImageLoad" 
                  ref="singleImage" 
                />
                <v-progress-circular
                  v-if="localImages[0].isUploading"
                  indeterminate
                  color="primary"
                  size="36"
                  class="image-upload-progress"
                ></v-progress-circular>
              </div>

              <!-- 时间轴列表 -->
              <div class="timeline-list">
                <!-- 已经发生的故事 -->
                <div v-if="pastStories.length">
                  <div class="section-title">在这一天</div>
                  <div v-if="pastStories.length > 1" class="timeline-group">
                    <div v-for="(story, index) in pastStories" :key="story.id" 
                      :class="['timeline-item', {'with-border': index !== pastStories.length - 1}]">
                      <div class="timeline-content">
                        <span class="timeline-emoji">{{ story.emoji }}</span>
                        <div class="timeline-info">
                          <span class="timeline-name">{{ story.name }}</span>
                          <span class="timeline-time">{{ calculatePhotoTimeDiff(story.startDate) }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="timeline-item" v-for="story in pastStories" :key="story.id">
                    <div class="timeline-content">
                      <span class="timeline-emoji">{{ story.emoji }}</span>
                      <div class="timeline-info">
                        <span class="timeline-name">{{ story.name }}</span>
                        <span class="timeline-time">{{ calculatePhotoTimeDiff(story.startDate) }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 还未发生的故事 -->
                <div v-if="futureStories.length">
                  <div class="section-title">即将发生</div>
                  <div v-if="futureStories.length > 1" class="timeline-group">
                    <div v-for="(story, index) in futureStories" :key="story.id" 
                      :class="['timeline-item', {'with-border': index !== futureStories.length - 1}]">
                      <div class="timeline-content">
                        <span class="timeline-emoji">{{ story.emoji }}</span>
                        <div class="timeline-info">
                          <span class="timeline-name">{{ story.name }}</span>
                          <span class="timeline-time">{{ calculatePhotoTimeDiff(story.startDate) }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="timeline-item" v-for="story in futureStories" :key="story.id">
                    <div class="timeline-content">
                      <span class="timeline-emoji">{{ story.emoji }}</span>
                      <div class="timeline-info">
                        <span class="timeline-name">{{ story.name }}</span>
                        <span class="timeline-time">{{ calculatePhotoTimeDiff(story.startDate) }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 添加新的浮窗指引 -->
                <div v-if="!selectedStories.length && localImages.length > 0" class="guide-tooltip">
                  <div class="guide-content">
                    <div class="guide-title">📸 设置个故事</div>
                    <p>试试把自己设置为故事，你就可以知道几岁拍摄这张照片了！</p>
                    <div class="guide-arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-container>
      </v-card-text>

      <!-- 底部操作 -->
      <div class="bottom-actions">
        <v-btn
          class="circle-btn"
          icon
          @click="handleStoryClick"
        >
          <v-icon>mdi-account-multiple</v-icon>
        </v-btn>
        <v-btn
          class="circle-btn"
          icon
          @click="changeImage"
        >
          <v-icon>mdi-image</v-icon>
        </v-btn>
        <v-btn
          class="circle-btn"
          icon
          @click="goToTimelime"
        >
          <v-icon>mdi-timeline</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
  
  <!-- 修改添加故事弹窗 -->
  <v-dialog v-model="showAddEventDialog" max-width="400">
    <v-card class="add-event-card">
      <div class="emoji-selector" @click="showEmojiPicker = true">
        <span class="selected-emoji">{{ newEvent.emoji }}</span>
      </div>

      <v-card-text>
        <!-- 添加类型选择 -->
        <v-select
          v-model="newEvent.type"
          :items="timelineTypes"
          label="记录类型"
          variant="outlined"
          density="comfortable"
          hide-details
          class="mb-4"
        ></v-select>

        <v-text-field
          v-model="newEvent.name"
          :label="newEvent.type === 'age' ? '名字' : '事件名称'"
          type="text"
          variant="outlined"
          density="comfortable"
          hide-details
          class="mb-4"
        ></v-text-field>

        <v-text-field
          v-model="newEvent.startDate"
          :label="newEvent.type === 'age' ? '出生日期' : '开始日期'"
          type="date"
          variant="outlined"
          density="comfortable"
          hide-details
        ></v-text-field>
      </v-card-text>

      <v-card-actions class="pb-4 px-6">
        <v-btn 
          variant="outlined" 
          @click="showAddEventDialog = false"
          class="dialog-action-btn"
          style="margin-right: 12px"
        >
          取消
        </v-btn>
        <v-btn 
          color="primary" 
          @click="addEvent"
          class="dialog-action-btn"
        >
          确定
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  
  <!-- 在 template 中���加故事弹窗 -->
  <v-dialog
    v-model="storyDialog"
    :fullscreen="false"
    :scrim="true"
    transition="dialog-bottom-transition"
    content-class="story-dialog"
    @click:outside="closeStoryDialog"
    width="100%"
  >
    <v-card class="story-card">
      <v-toolbar flat class="story-toolbar">
        <v-toolbar-title class="text-h6">我的故事</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          variant="text"
          class="new-story-btn"
          @click="showAddEventDialog = true"
        >
          新建
        </v-btn>
      </v-toolbar>

      <v-card-text class="story-content">
        <!-- 没有数据时显示提示文案 -->
        <div v-if="!storyData.length" class="story-description">
          添加主角，让每张照片告诉你，主角当时几岁
        </div>
        
        <!-- 有数据时显示故事列表 -->
        <div v-else class="story-list">
          <div 
            v-for="(story, index) in storyData" 
            :key="story.id"
            class="story-item"
          >
            <div class="story-item-content">
              <span class="story-emoji">{{ story.emoji }}</span>
              <div class="story-info">
                <div class="story-title-row">
                  <div class="story-title-wrapper">
                    <span class="story-name">{{ story.name }}</span>
                    <span class="story-type-tag" :class="story.type">
                      {{ story.type === 'age' ? '成长记录' : '纪念日' }}
                    </span>
                  </div>
                  <v-menu>
                    <template v-slot:activator="{ props }">
                      <v-btn
                        icon
                        density="comfortable"
                        variant="plain"
                        size="small"
                        v-bind="props"
                        class="more-btn"
                        @click.stop
                      >
                        <v-icon size="small">mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click.stop="editStory(index)">
                        <v-list-item-title>编辑</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click.stop="removeEvent(index)">
                        <v-list-item-title class="text-error">删除</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <div class="story-dates">
                  <span class="story-label">{{ story.type === 'age' ? '出生' : '开始' }}：</span>
                  <span class="story-start-date">{{ formatStoryDate(story.startDate) }}-至今</span>
                </div>
                <div class="story-duration">
                  <span class="story-label">{{ story.type === 'age' ? '年龄' : '时间' }}：</span>
                  <span class="story-label">{{ calculateCurrentTimeDiff(story.startDate) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- 在 template 中添加 Emoji 选择弹 -->
  <v-dialog v-model="showEmojiPicker" max-width="300" :scrim="false">
    <v-card class="emoji-picker-card">
      <v-card-text class="emoji-grid">
        <span
          v-for="emoji in availableEmojis"
          :key="emoji"
          class="emoji-item"
          @click="selectEmoji(emoji)"
        >
          {{ emoji }}
        </span>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- Toast 提示 -->
  <v-snackbar v-model="showToast" :color="toastColor" :timeout="3000">
    {{ toastMessage }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="showToast = false">
        关闭
      </v-btn>
    </template>
  </v-snackbar>
</template>
  
  <script setup>
  import { ref, computed, watch, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { getTimeAgoString } from '@/utils/dateUtils';
  import storyApi from '@/services/storyApi';
  
  const store = useStore();
  
  const props = defineProps({
    images: {
      type: Array,
      default: () => []
    }
  });
  
  const dialog = ref(false);
  const date = ref(new Date());
  const dateMenu = ref(false);
  
  const localImages = ref([...props.images]);

  const showToast = ref(false);
  const toastMessage = ref('');
  const toastColor = ref('success');
  
  // 获取故事开始时间
  const storyStartDate = computed(() => {
    const currentStory = store.getters['story/currentStory'];
    return currentStory ? new Date(currentStory.startTime) : new Date();
  });
  
  // 修改 timeAgoString 计属性
  const timeAgoString = computed(() => {
    return getTimeAgoString(storyStartDate.value, date.value);
  });
  
  // 添加日期式化函数
  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}年${month}月${day}日`;
  };

  const showToastMessage = (message, color = 'success') => {
      toastMessage.value = message;
      toastColor.value = color;
      showToast.value = true;
    };
  
  // 修改图片上传成功后的处理
  const uploadSingleImage = async (image) => {
    try {
      const response = await storyApi.uploadImage(image.file);
      if (response.result === 0 && response.body && response.body.list && response.body.list.length > 0) {
        const uploadedImage = response.body.list[0];
        const index = localImages.value.findIndex(img => img.url === image.url);
        if (index !== -1) {
          localImages.value[index] = {
            ...localImages.value[index],
            isUploading: false,
            uploadFailed: false,
            serverUrl: uploadedImage.pictureUrls, // 存服务器返回图片URL
            dateTime: uploadedImage.dateTime    // 保存服务器回的时间
          };
          // 如果这是第一张图片，更新日期
          if (index === 0) {
            if (response.body.list[0].dateTime === null || response.body.list[0].dateTime === undefined){
              showToastMessage('照片拍摄日期已丢失', 'error');
              console.log("日期在哪里 ==== ",response.body.list[0].dateTime)
            }
            updateDate(uploadedImage.dateTime);
            
          }
        }
      } else {
        throw new Error('上传失败');
      }
    } catch (error) {
      console.error('上传图片出错:', error);
      const index = localImages.value.findIndex(img => img.url === image.url);
      if (index !== -1) {
        localImages.value[index] = {
          ...localImages.value[index],
          isUploading: false,
          uploadFailed: true
        };
      }
    }
  };
  
  const singleImageAspectRatio = ref(1);
  const singleImage = ref(null);
  
  const onSingleImageLoad = () => {
    if (singleImage.value) {
      const img = singleImage.value;
      singleImageAspectRatio.value = img.naturalWidth / img.naturalHeight;
    }
  };
  
  // 更期的函数
  const updateDate = (newDate) => {
    if (newDate) {
      date.value = new Date(newDate);
    } else {
      date.value = new Date();
    }
  };
  
  // 控 date 的变化
  watch(date, () => {
    // date 变��时，timeAgoString 会重算
    console.log('发生时间更新，新的间差:', timeAgoString.value);
    // 触新算 pastStories 和 futureStories
    pastStories.value = selectedStories.value.filter(story => new Date(story.startDate) <= new Date(date.value));
    futureStories.value = selectedStories.value.filter(story => new Date(story.startDate) > new Date(date.value));
  });
  
  // 监听 localImages 的变化
  watch(localImages, (newImages) => {
    if (newImages.length > 0 && newImages[0].dateTime) {
      updateDate(newImages[0].dateTime);
    } else if (newImages.length === 0) {
      updateDate(new Date());
    }
  }, { deep: true });
  
  // 修改更换图片方法
  const changeImage = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    
    input.onchange = async (event) => {
      const files = event.target.files;
      if (files && files[0]) {
        // 删除原有图片
        localImages.value = [];
        
        // 添加并上传新片
        const newImage = {
          url: URL.createObjectURL(files[0]),
          isUploading: true,
          uploadFailed: false,
          file: files[0]
        };
        
        localImages.value = [newImage];
        await uploadSingleImage(newImage);
      }
    };
    
    input.click();
  };

  // 去时轴
  const goToTimelime = () => {
    showToastMessage('请期待', 'success');
  };
  
  onMounted(() => {
    console.log('Mounted EditPost, images:', localImages.value);
    
    // 初始化故事数据
    initStoryData();
    
    // 如果有传入的图片，立即开始上传
    if (localImages.value.length > 0) {
      localImages.value.forEach(uploadSingleImage);
    }
    
    // 如果没有图片置默认日期为今天
    if (localImages.value.length === 0) {
      updateDate(new Date());
    } else if (localImages.value[0].dateTime) {
      // 如果有片，使用一张图片的日期
      updateDate(localImages.value[0].dateTime);
    }
    
    setTimeout(() => {
      dialog.value = true;
    }, 0);
  });
  
  // 定义故事数据结构和操作方法
  const STORAGE_KEY = 'timeline_stories';
  const storyData = ref([]);
  const showAddEventDialog = ref(false);
  const showEmojiPicker = ref(false);
  const availableEmojis = ['🐱', '🐶', '🎂', '🎓', '💼', '💕', '🏠', '✈️', '🎮', '📚'];

  // 新故事的数结构
  const newEvent = ref({
    name: '',
    emoji: '🐱',
    type: 'age', // 默认为年龄类型
    startDate: new Date().toISOString().substr(0, 10)
  });

  // 初始化故事数据
  const initStoryData = () => {
    try {
      const savedData = localStorage.getItem(STORAGE_KEY);
      storyData.value = savedData ? JSON.parse(savedData).map(story => ({
        ...story,
        selected: true // 默认全部选中
      })) : [];
    } catch (error) {
      console.error('读取故事数据失败:', error);
      storyData.value = [];
    }
  };

  // 保存故事到本地
  const saveStoryToLocal = (story) => {
    try {
      storyData.value.push(story);
      localStorage.setItem(STORAGE_KEY, JSON.stringify(storyData.value));
      return true;
    } catch (error) {
      console.error('保存故事失败:', error);
      return false;
    }
  };

  // 选择 emoji
  const selectEmoji = (emoji) => {
    newEvent.value.emoji = emoji;
    showEmojiPicker.value = false;
  };

  // 加故事
  const addEvent = () => {
    if (newEvent.value.name && newEvent.value.startDate) {
      const story = {
        id: Date.now(),
        emoji: newEvent.value.emoji,
        name: newEvent.value.name,
        type: newEvent.value.type, // 添加类型字段
        startDate: newEvent.value.startDate,
        createTime: new Date().toISOString(),
        selected: true
      };

      if (saveStoryToLocal(story)) {
        showAddEventDialog.value = false;
        // 重置表单
        newEvent.value = {
          name: '',
          emoji: '🐱',
          type: 'age',
          startDate: new Date().toISOString().substr(0, 10)
        };
      }
    }
  };

  // 删除故事
  const removeEvent = (index) => {
    storyData.value.splice(index, 1);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(storyData.value));
  };

  // 添加故事日期格式化函数
  const formatStoryDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}年${month}月${day}日`;
  };

  // 修改计算照片时差的函数
  const calculatePhotoTimeDiff = (storyDate) => {
    const photoDate = new Date(date.value);
    const storyDateTime = new Date(storyDate);
    const story = storyData.value.find(s => s.startDate === storyDate);
    
    // 判断是否是未来时间
    const isFuture = storyDateTime > photoDate;
    
    // 计算时间差（毫秒）
    const timeDiff = Math.abs(photoDate - storyDateTime);
    
    // 计算年、月、天
    const years = Math.floor(timeDiff / (365.25 * 24 * 60 * 60 * 1000));
    const months = Math.floor((timeDiff % (365.25 * 24 * 60 * 60 * 1000)) / (30.44 * 24 * 60 * 60 * 1000));
    const days = Math.floor((timeDiff % (30.44 * 24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000));
    
    // 构建时间字符串的函数
    const buildTimeString = () => {
      let timeStr = '';
      if (years > 0) {
        timeStr += `${years}${story?.type === 'age' && !isFuture ? '岁' : '年'}`;
      }
      if (months > 0) {
        timeStr += `${timeStr ? ' ' : ''}${months}个月`;
      }
      if (days > 0 || (!years && !months)) { // 如果天数大于0，或者年月都是0，则显示天数
        timeStr += `${timeStr ? ' ' : ''}${days}天`;
      }
      return timeStr;
    };

    const timeStr = buildTimeString();
    
    if (isFuture) {
      // 未来时间的显示逻辑
      if (story?.type === 'age') {
        return `${timeStr}后出生`;
      } else {
        return `${timeStr}后发生`;
      }
    } else {
      // 过去时间的显示逻辑
      return timeStr;
    }
  };

  // 修改计算当前时间差的方法
  const calculateCurrentTimeDiff = (startDate) => {
    const start = new Date(startDate);
    const now = new Date();
    const story = storyData.value.find(s => s.startDate === startDate);
    
    // 计算时间差（毫秒）
    const timeDiff = now - start;
    
    // 计算年、月、天
    const years = Math.floor(timeDiff / (365.25 * 24 * 60 * 60 * 1000));
    const months = Math.floor((timeDiff % (365.25 * 24 * 60 * 60 * 1000)) / (30.44 * 24 * 60 * 60 * 1000));
    const days = Math.floor((timeDiff % (30.44 * 24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000));
    
    // 根据类型返回不同格式
    if (story?.type === 'age') {
      return `${years}岁${months}个月${days}天`;
    } else {
      return `${years}年${months}个月${days}天`;
    }
  };

  // 添加计算属性获取选中的故事
  const selectedStories = computed(() => {
    return storyData.value.filter(story => story.selected);
  });

  // 添加故事弹窗的状态控制
  const storyDialog = ref(false);

  // 添加处理故事按钮点击的方法
  const handleStoryClick = () => {
    if (!storyData.value.length) {
      // 如果没有主角，直接打开新建主角弹窗
      showAddEventDialog.value = true;
    } else {
      // 有主角时，显示主角列表弹窗
      storyDialog.value = true;
    }
  };

  // 添加关闭故事窗的方法
  const closeStoryDialog = () => {
    storyDialog.value = false;
  };

  // 添加计算属性来区分已经发生和未发生的故事
  const pastStories = computed(() => {
    return selectedStories.value.filter(story => new Date(story.startDate) <= new Date(date.value));
  });

  const futureStories = computed(() => {
    return selectedStories.value.filter(story => new Date(story.startDate) > new Date(date.value));
  });

  // 添加类型选项
  const timelineTypes = [
    { title: '记录成长', value: 'age', description: '记录人或宠物的年龄变化' },
    { title: '纪念日', value: 'anniversary', description: '记录特殊日子' }
  ];
  </script>
  
  <style scoped>
  
  /* 使用深度选择器来修改 Vuetify 组件的样式 */
  :deep(.v-text-field .v-input__control) {
    min-height: 40px;
  }
  
  :deep(.v-text-field .v-input__slot) {
    min-height: 40px;
  }
  
  
  .single-preview-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 12px;
    box-shadow: none !important; /* 移除阴影 */
    display: block; /* 确保没有额外的间距 */
  }
  
  .single-preview-img.crop {
    object-fit: cover;
    border-radius: 12px; /* 确保裁剪模式下也有圆角 */
  }
  
  .v-list {
    background-color: transparent !important;
  }
  
  .v-list-item {
    min-height: 48px;
    padding-left: 0;
    padding-right: 0;
  }
  
  
  /* 调整时间显示样式 */
  .v-list-item-subtitle.text-caption {
    padding-top: 0;
    padding-bottom: 0;
  }
  
  /* 修改过渡动画样 */
  :deep(.dialog-transition) {
    transition: transform 0.3s ease-in-out !important;
  }
  
  :deep(.dialog-bottom-transition-enter-active),
  :deep(.dialog-bottom-transition-leave-active) {
    transition: transform 0.3s ease-in-out !important;
  }
  
  :deep(.dialog-bottom-transition-enter-from),
  :deep(.dialog-bottom-transition-leave-to) {
    transform: translateY(100%) !important;
  }
  
  :deep(.dialog-bottom-transition-enter-to),
  :deep(.dialog-bottom-transition-leave-from) {
    transform: translateY(0) !important;
  }
  
  /* ... 其他样式保持不变 ... */
  
  .image-upload-progress {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .date-display:hover {
    background-color: #F8F9FA !important;
  }
  
  .date-icon {
    margin-right: 12px;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  
  .date-text {
    font-size: 26px !important;
    font-weight: bold !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  
  .action-btn {
    height: 44px !important;
    font-weight: 500 !important;
    letter-spacing: 0.25px !important;
    text-transform: none !important;
    border-radius: 8px !important;
    padding: 0 20px !important;
  }
  
  .action-btn:nth-child(1),
  .action-btn:nth-child(2) {
    min-width: 100px !important;
    max-width: 100px !important;
    background-color: #F8F9FA !important;
    color: rgba(0, 0, 0, 0.87) !important;
    border: none !important;
  }
  
  .action-btn:nth-child(3) {
    min-width: 120px !important;
    max-width: 120px !important;
    background-color: #1A73E8 !important;
    color: white !important;
  }
  
  .action-btn:hover {
    opacity: 0.9 !important;
  }
  
  .remove-btn {
    opacity: 0.6;
    transform: rotate(45deg);
  }
  
  .remove-btn:hover {
    opacity: 1;
    background: transparent !important;
  }
  
  .remove-btn::before {
    display: none !important;
  }
  
  /* 修改故事弹窗样式 */
  :deep(.story-dialog) {
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin: 0 !important;
    max-width: 100% !important;
    width: 100% !important;
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .story-card {
    position: relative;
    width: 100%;
    height: 60vh !important;
    max-height: 60vh !important;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    overflow: hidden;
    background-color: white;
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  
  .story-toolbar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    flex-shrink: 0;
  }
  
  .new-story-btn {
    font-weight: 500;
  }
  
  .story-content {
    padding: 24px;
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .story-description {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    text-align: center;
  }

  .add-event-card {
    padding: 24px 0;
  }

  .emoji-selector {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .emoji-selector:hover {
    background-color: #eeeeee;
  }

  .selected-emoji {
    font-size: 32px;
  }

  .emoji-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 12px;
    padding: 16px;
  }

  .emoji-item {
    font-size: 24px;
    padding: 8px;
    cursor: pointer;
    text-align: center;
    border-radius: 8px;
    transition: background-color 0.3s;
  }

  .emoji-item:hover {
    background-color: #f5f5f5;
  }

  .date-range {
    margin-top: 16px;
  }

  .action-btn {
    min-width: 120px;
  }

  /* 修改 Emoji 择弹窗样式 */
  .emoji-picker-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px;
  }


  .story-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    background-color: white;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    transition: all 0.3s;
  }

  .story-item:hover {
    border-color: #e0e0e0;
    background-color: #fafafa;
  }

  .story-item-selected {
    background-color: #f5f5f5;
    border-color: transparent;
  }

  .story-item-selected:hover {
    background-color: #eeeeee;
    border-color: transparent;
  }

  .story-item-content {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1;
    cursor: pointer;
  }

  .story-emoji {
    font-size: 24px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
  }

  .story-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .story-name {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
  }

  .story-dates {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
  }

  .story-start-date {
    color: rgba(0, 0, 0, 0.6);
  }

  .story-duration {
    color: var(--v-primary-base);
    font-weight: 500;
  }

  .story-title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .story-label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: normal;
  }

  .story-dates, .story-duration {
    display: flex;
    align-items: center;
    gap: 4px;
    color: rgba(0, 0, 0, 0.6);
  }

  /* 添加下拉菜单的样式 */
  :deep(.v-menu > .v-overlay__content) {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  position: absolute;
  margin-top: 4px !important;
  z-index: 1000;
}

  :deep(.v-list) {
    background-color: white !important;  /* 修改为白色背景 */
    padding: 4px !important;
  }

  :deep(.v-list-item) {
    border-radius: 4px;
    min-height: 40px !important;
  }

  :deep(.v-list-item:hover) {
    background-color: #f5f5f5 !important;
  }

  :deep(.v-list-item__title) {
    font-size: 14px;
  }


  /* 调整文本编辑区域的样 */
  .post-content {
  font-size: 18px !important;
  /* color: rgba(0, 0, 0, 0.87) !important; */
  background: transparent !important;
  margin: 20px 0 0 0 !important; /* 添加顶部外边距20px */
}

:deep(.post-content) {
  .v-field__input,
  textarea {
    padding-top: 0 !important; /* 移除输入框的顶部内边距 */
    margin-top: 0 !important; /* 移除输入框的顶部外边距 */
  }
}

  /* 移除可能的背景遮罩 */
  :deep(.v-field__overlay) {
    display: none !important;
  }

.date-display {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 0px 0px 0px 0px !important;
}


  /* 添加新的样式 */
  .main-container {
    background-color: #F3F2F6 !important;
    height: calc(100vh - 80px) !important; /* 减去底部操作栏的高度 */
    margin-bottom: 0 !important; /* 移除底部margin */
    overflow-y: auto !important;
    padding-bottom: 100px !important; /* 增加底部内边距，避免内容被底部操作栏遮挡 */

    /* 隐藏滚动条但保持可滚动 */
    scrollbar-width: none !important; /* Firefox */
    -ms-overflow-style: none !important; /* IE and Edge */
    &::-webkit-scrollbar {
      display: none !important; /* Chrome, Safari and Opera */
    }
  }

  /* 修改已有的样式 */
  .edit-post-card {
    display: flex;
    flex-direction: column;
    height: 100vh !important; /* 设置为全屏高度 */
    background-color: #F3F2F6 !important;
  }

  /* 确保底部操作栏样式正确 */
  .bottom-actions {
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    background-color: transparent !important; /* 移除背景色 */
    z-index: 100 !important;
    padding: 16px !important;
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
  }

  /* 修改按钮样式 */
  .action-btn {
    width: 56px !important;
    height: 56px !important;
    border-radius: 50% !important;
    background-color: rgba(255, 255, 255, 0.9) !important; /* 半透明白色背景 */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
  }

  .action-btn:hover {
    background-color: white !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
  }

  /* 修改图标样式 */
  :deep(.v-btn__content) {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .v-icon {
    font-size: 24px !important;
  }

  /* 修改文本框样式 */
  :deep(.v-text-field .v-field__outline) {
    border-radius: 12px !important;
  }

  .module-wrapper:last-child {
    margin-bottom: 0;  /* 最后一模块不需要底部间距 */
  }

  /* 移除其他可能影响间距的样式 */
  .v-row {
    margin: 0 !important; /* 确保 row 没有额外的 margin */
  }

  .v-col {
    padding: 0 !important; /* 确保 col 没有额外的 padding */
  }

  .image-container {
    position: relative;
    margin: 0;
    padding: 20px 0 !important;
  }


  /* 如果还有阴影，可以添加以下样式 */
  .single-preview-img:not(.crop) {
    filter: none !important; /* 移除任何可能的滤镜效果 */
    -webkit-filter: none !important;
  }

  /* 确保容器本身也没有阴影 */
  .image-container img {
    box-shadow: none !important;
  }

  /* 移除文本框的白色蒙层和调整样式 */
  :deep(.v-field) {
    /* 移除蒙层 */
    .v-field__overlay {
      background-color: transparent !important;
      border-color: transparent !important;
      display: none !important;
    }

    /* 移除背景色 */
    .v-field__field {
      background-color: transparent !important;
    }

    /* 保输入域完透明 */
    .v-field__input {
      background-color: transparent !important;
    }
  }

  /* 确保 v-container 也有足够的底部空间 */
  .v-container.fluid {
    padding: 16px !important;
    padding-bottom: 0px !important; /* 增加容器底部内边距 */
  }

  /* 修改模块间距 */
  .module-wrapper {
    margin-bottom: 10px !important; /* 统一模块间 */
  }

  /* 修改时间轴列表样式 */
  .timeline-list {
    display: flex;
    flex-direction: column;
    gap: 12px !important;
    margin-top: 20px !important;
    margin-bottom: 0 !important;
  }

  .timeline-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px !important;
    background-color: #F8F9FA !important;
    border-radius: 12px;
    transition: background-color 0.3s;
  }

  .timeline-content {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1;
  }

  .timeline-emoji {
    font-size: 20px !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .timeline-name {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: rgba(0, 0, 0, 0.87) !important;
    margin-right: 12px !important;
  }

  .timeline-time {
    font-size: 13px !important;
    color: #1A73E8 !important;
    font-weight: 400 !important;
  }

  .timeline-item.selected {
    background-color: #E8F0FE !important;
    border: 1px solid #1A73E8 !important;
  }

  .white-module {
    background-color: white;
    border-radius: 12px;
    padding: 20px !important;
    margin: 0 !important;
  }

  .section-title {
    font-size: 14px;
    margin-bottom: 12px !important;
    color: rgba(0, 0, 0, 0.6);
    padding: 0;
  }


  :deep(.v-text-field) {
    .v-field__input {
      font-size: 16px !important;
      padding: 8px 16px !important;
      background: transparent !important;
      box-shadow: none !important;
    }
    
    .v-field__outline {
      color: rgba(0, 0, 0, 0.25) !important;
    }
    
    &:hover .v-field__outline {
      color: rgba(0, 0, 0, 0.40) !important;
    }
    
    &--focused .v-field__outline {
      color: #1A73E8 !important;
    }
  }

  :deep(.date-picker) {
    border-radius: 12px;
    overflow: hidden;
    box-shadow: none !important;
    
    .v-date-picker-header {
      padding: 16px 16px 0;
    }
    
    .v-date-picker-month {
      padding: 0 16px 16px;
    }
    
    .v-date-picker-table {
      height: auto;
    }
    
    .v-btn.v-btn--active {
      background-color: #1A73E8 !important;
      color: white !important;
    }
    
    .v-btn:not(.v-btn--active):hover {
      background-color: rgba(26, 115, 232, 0.1) !important;
    }
  }

  .guide-tooltip {
    position: fixed;
    left: 16px;
    bottom: 88px;
    z-index: 99;
    max-width: 280px;
    animation: fadeIn 0.3s ease-out;
  }

  .guide-content {
    background: rgba(0, 0, 0, 0.9);
    color: white;
    padding: 16px;
    border-radius: 12px;
    font-size: 14px;
    line-height: 1.6;
    position: relative;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }

  .guide-title {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .guide-arrow {
    position: absolute;
    bottom: -8px;
    left: 50px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid rgba(0, 0, 0, 0.9);
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* 修改输入框线条颜色 */
  :deep(.v-field__outline) {
    --v-field-border-opacity: 0.80 !important; /* 增加边框透明度 */
  }

  /* 修改输入框获得焦点时的颜色 */
  :deep(.v-field--focused .v-field__outline) {
    --v-field-border-opacity: 0.8 !important;
  }

  /* 新增弹窗按钮样式 */
  .dialog-action-btn {
    flex: 1 !important; /* 让按钮占据相等宽度 */
    height: 40px !important;
    border-radius: 6px !important; /* 改为矩形风格 */
    text-transform: none !important;
    font-weight: 500 !important;
    letter-spacing: 0.25px !important;
  }

  /* 取消按钮样式 */
  .dialog-action-btn:first-child {
    border: 1px solid rgba(0, 0, 0, 0.23) !important; /* 加深边框颜色 */
    color: rgba(0, 0, 0, 0.87) !important;
    background-color: transparent !important;
  }

  /* 确定按钮样式 */
  .dialog-action-btn:last-child {
    background-color: #1A73E8 !important; /* 使用主题蓝色 */
    color: white !important; /* 文字改为白色 */
    border: none !important;

    /* 确保按钮内所有文字都是白色 */
    .v-btn__content {
      color: white !important;
    }
  }

  /* 按钮悬停效果 */
  .dialog-action-btn:last-child:hover {
    background-color: #1557B0 !important; /* 悬停时颜色加深 */
    
    .v-btn__content {
      color: white !important; /* 确保悬停时文字依然是白色 */
    }
  }

  /* 移除旧的 action-btn 样式，避免冲突 */
  .add-event-card .action-btn {
    min-width: unset !important;
    max-width: unset !important;
  }

  /* 修改输入框 label 的颜色 */
  :deep(.v-field__label) {
    color: rgba(0, 0, 0, 0.6) !important; /* 未聚焦时的颜色 */
  }

  /* 输入框聚焦时 label 的颜色 */
  :deep(.v-field--focused .v-field__label) {
    color: #1A73E8 !important; /* 聚焦时的颜色 */
  }

  /* 输入框有值时 label 的颜色 */
  :deep(.v-field--active .v-field__label) {
    color: rgba(0, 0, 0, 0.6) !important; /* 有值时的颜色 */
  }

  .story-title-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .story-type-tag {
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 4px;
    font-weight: normal;
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.6);
  }

  .story-type-tag.age {
    background-color: #E8F0FE;
    color: #1A73E8;
  }

  .story-type-tag.anniversary {
    background-color: #FEE8E6;
    color: #D93025;
  }

  /* 调整标题行的样式 */
  .story-title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  /* 确保名字和标签在同一行 */
  .story-name {
    margin-right: 8px;
  }

  /* 修改故事列表项的样式和结构 */
  .story-list {
    display: flex;
    flex-direction: column;
    gap: 16px; /* 增加故事项之间的间距 */
    padding: 0;
  }

  .story-item {
    background-color: white;
    border: 1px solid #E0E0E0; /* 灰色边框 */
    border-radius: 12px;
    padding: 16px;
    transition: all 0.3s;
  }

  .story-item-content {
    display: flex;
    align-items: flex-start; /* 改为顶部对齐 */
    gap: 16px;
  }

  .story-emoji {
    font-size: 24px;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F5F5F5;
    border-radius: 8px;
    flex-shrink: 0; /* 防止emoji缩小 */
  }

  .story-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .story-title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
  }

  .story-name {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
  }

  .story-dates,
  .story-duration {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .more-btn {
    opacity: 0.6;
    margin: -8px;
  }

  .more-btn:hover {
    opacity: 1;
    background-color: transparent !important;
  }

  :deep(.v-menu) {
    display: inline-block;
  }

  /* 重写底部按钮样式，提高优先级 */
  .bottom-actions :deep(.circle-btn.v-btn) {
    width: 56px !important;
    height: 56px !important;
    border-radius: 50% !important;
    background-color: white !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
  }

  .bottom-actions :deep(.circle-btn.v-btn:hover) {
    background-color: white !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
  }

  .bottom-actions :deep(.circle-btn .v-btn__content) {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .bottom-actions :deep(.circle-btn .v-icon) {
    font-size: 24px !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  </style>