<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="sidebar-container">
    <div class="sidebar-header pa-4 d-flex align-center justify-space-between">
      <span class="text-h6" @click="printLocalStorage">时间线相册</span>
      <v-btn icon="mdi-close" size="small" @click="$emit('close')" class="close-btn" variant="text"></v-btn>
    </div>
    <v-divider></v-divider>
    <v-list class="pa-0">
      <v-list-item @click="navigate('Feed', '动态')" :to="{ name: 'Feed' }" link class="py-4">
        <template v-slot:prepend>
          <v-icon size="24">mdi-home-variant</v-icon>
        </template>
        <v-list-item-title class="text-subtitle-1 font-weight-medium">动态</v-list-item-title>
      </v-list-item>
      
      <v-list-item @click="navigate('Album', '相册')" :to="{ name: 'Album' }" link class="py-4">
        <template v-slot:prepend>
          <v-icon size="24">mdi-image-multiple</v-icon>
        </template>
        <v-list-item-title class="text-subtitle-1 font-weight-medium">相册</v-list-item-title>
      </v-list-item>
      
      <v-list-item 
        @click="navigate('Stories', '故事')" 
        :to="{ name: 'Stories' }" 
        :active="currentRoute === '/stories' && !viewingAllStories"
        link 
        class="py-4"
      >
        <template v-slot:prepend>
          <v-icon size="24">mdi-book-open-variant</v-icon>
        </template>
        <v-list-item-title class="text-subtitle-1 font-weight-medium">故事</v-list-item-title>
        <template v-slot:append>
          <v-tooltip text="新建故事" location="right">
            <template v-slot:activator="{ props }">
              <v-btn icon size="small" variant="text" v-bind="props" @click.stop.prevent="openNewStoryModal">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </template>
      </v-list-item>
      
      <!-- 动态创建的故事本列表 -->
      <v-list-item 
        v-for="story in displayedStories" 
        :key="story.id" 
        :to="{ name: 'StoryDetail', params: { id: story.id } }" 
        :active="currentRoute === `/story/${story.id}`"
        class="pl-8"
        @click="navigateToStory(story)"
      >
        <v-list-item-title>{{ story.title }}</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="stories.length > 3" :to="{ name: 'Stories', query: { viewAll: 'true' } }" :active="viewingAllStories" class="pl-8">
        <v-list-item-title class="text-caption text-grey">
          查看所有故事本
          <v-icon size="small" color="grey" class="ms-1">mdi-chevron-right</v-icon>
        </v-list-item-title>
      </v-list-item>
    </v-list>
    
    <!-- 修改登录/退出按钮 -->
    <v-divider></v-divider>
    <div class="login-button-container pa-4">
      <v-btn 
        block 
        color="error" 
        @click="logout" 
        v-if="isLoggedIn"
      >
        退出登录
      </v-btn>
    </div>
  </div>
  <NewStoryModal v-model="showNewStoryModal" @close="closeNewStoryModal" @create="createStory" />
  <LoginRegisterModal v-model="showLoginModal" />
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { eventBus } from '@/utils/eventBus';
import NewStoryModal from '../modals/NewStoryModal.vue';
import LoginRegisterModal from '../modals/LoginRegisterModal.vue';

export default {
  name: 'SidebarMenu',
  components: {
    NewStoryModal,
    LoginRegisterModal
  },
  props: {
    currentRoute: {
      type: String,
      required: true
    }
  },
  emits: ['close', 'navigate'],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const showNewStoryModal = ref(false);
    const showLoginModal = ref(false);
    const stories = computed(() => store.getters['story/storyList']);
    const isLoggedIn = computed(() => store.getters['auth/isAuthenticated']);

    const viewingAllStories = computed(() => {
      return route.name === 'Stories' && route.query.viewAll === 'true';
    });

    const displayedStories = computed(() => {
      return stories.value.slice(0, 3);
    });

    watch(isLoggedIn, (newValue) => {
      if (newValue) {
        store.dispatch('story/fetchStoryList');
      }
    });

    const navigate = (routeName, title) => {
      console.log(`Attempting to navigate to ${routeName} with title ${title}`);
      router.push({ name: routeName }).then(() => {
        console.log(`Successfully navigated to ${routeName}`);
        emit('navigate', title);
        emit('close');
      }).catch(err => {
        console.error('Navigation failed:', err);
        console.log('Current route:', router.currentRoute.value);
      });
    };

    const navigateToAllStories = () => {
      navigate('Stories', '所有故事本');
    };

    const openNewStoryModal = (event) => {
      event.stopPropagation();
      event.preventDefault();
      showNewStoryModal.value = true;
    };

    const closeNewStoryModal = () => {
      showNewStoryModal.value = false;
    };

    const createStory = (story) => {
      stories.value.push({
        id: story.id,
        name: story.name
      });
    };

    const openLoginModal = () => {
      showLoginModal.value = true;
    };

    const logout = () => {
      store.dispatch('auth/logout');
      router.push('/');
    };

    const printLocalStorage = () => {
      console.log('本地存储的内容：');
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        const value = localStorage.getItem(key);
        console.log(`${key}: ${value}`);
      }
    };

    const navigateToStory = async (story) => {
      // 立即更新 store 中的 currentStory
      store.commit('story/SET_CURRENT_STORY', story);
      
      // 移除 storyData 查询参数，因为我们现在依赖 store 中的数据
      router.push({ 
        name: 'StoryDetail', 
        params: { id: story.id }
      });
      emit('navigate', story.title);
      emit('close');
    };

    return {
      showNewStoryModal,
      stories,
      viewingAllStories,
      displayedStories,
      navigate,
      navigateToAllStories,
      openNewStoryModal,
      closeNewStoryModal,
      createStory,
      showLoginModal,
      isLoggedIn,
      openLoginModal,
      logout,
      printLocalStorage,
      navigateToStory
    };
  },
  created() {
    eventBus.on('story-created', this.createStory);
  },
  beforeUnmount() {
    eventBus.off('story-created', this.createStory);
  }
}
</script>

<style scoped>
.sidebar-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.sidebar-header {
  flex-shrink: 0;
}
.close-btn {
  box-shadow: none !important;
}
.v-list {
  flex-grow: 1;
  overflow-y: auto;
}
.v-list-item {
  min-height: 48px;
}
.v-list-item:hover {
  background-color: #fafafa;
}
.login-button-container {
  margin-top: auto;
}
</style>