<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card class="edit-post-card">
      <v-toolbar flat class="edit-post-toolbar">
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="text-subtitle-1">新建帖子</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="publishPost" :disabled="!canPublish">
          发布
        </v-btn>
      </v-toolbar>
      
      <v-card-text class="pa-0">
        <v-container fluid class="pa-4">
          <!-- 图片区域 -->
          <v-row no-gutters v-if="localImages.length > 0" class="mb-4">
            <v-col cols="12">
              <!-- 单张图片显示 -->
              <div v-if="localImages.length === 1" class="single-image-container" :style="singleImageStyle">
                <img :src="localImages[0].url" :class="['single-preview-img', { 'crop': singleImageAspectRatio < 3/4 }]" @load="onSingleImageLoad" ref="singleImage" />
                <v-progress-circular
                  v-if="localImages[0].isUploading"
                  indeterminate
                  color="primary"
                  size="36"
                  class="image-upload-progress"
                ></v-progress-circular>
                <v-btn v-if="localImages[0].uploadFailed" icon small class="image-action-btn retry-btn" @click="retryUpload(localImages[0])">
                  <v-icon small color="white">mdi-refresh</v-icon>
                </v-btn>
                <v-btn icon small class="image-action-btn delete-btn" @click="deleteImage(localImages[0])">
                  <v-icon small color="white">mdi-close</v-icon>
                </v-btn>
              </div>
              
              <!-- 多张图片显示 -->
              <div v-else class="image-grid-container">
                <draggable 
                  v-model="localImages" 
                  item-key="url"
                  @start="drag=true" 
                  @end="drag=false"
                  class="d-flex image-grid"
                >
                  <template #item="{ element }">
                    <div class="image-item">
                      <img :src="element.url" class="preview-img" />
                      <v-progress-circular
                        v-if="element.isUploading"
                        indeterminate
                        color="primary"
                        size="24"
                        class="image-upload-progress"
                      ></v-progress-circular>
                      <v-btn v-if="element.uploadFailed" icon small class="image-action-btn retry-btn" @click="retryUpload(element)">
                        <v-icon small color="white">mdi-refresh</v-icon>
                      </v-btn>
                      <v-btn icon small class="image-action-btn delete-btn" @click="deleteImage(element)">
                        <v-icon small color="white">mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </draggable>
              </div>
            </v-col>
          </v-row>

          <!-- 文字区域 -->
          <v-row no-gutters class="mb-4">
            <v-col cols="12">
              <v-textarea
                v-model="postContent"
                placeholder="写下这一刻的想法..."
                auto-grow
                rows="3"
                class="post-content"
                hide-details
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>

          <!-- 按钮区域 -->
          <v-row no-gutters class="mb-4">
            <v-col cols="12" class="d-flex">
              <v-btn class="mr-2" @click="uploadImage" :disabled="localImages.length >= 100">
                <v-icon left>mdi-image</v-icon>
                上传图片
              </v-btn>
              <!-- 注释掉"找灵感"按钮 -->
              <!--
              <v-btn @click="openInspirationDialog">
                <v-icon left>mdi-lightbulb-outline</v-icon>
                找灵感
              </v-btn>
              -->
            </v-col>
          </v-row>

          <!-- 日期区域和置顶开关 -->
          <v-row no-gutters class="mt-4">
            <v-col cols="12">
              <v-list class="pa-0">
                <v-list-item @click="dateMenu = true" class="px-0">
                  <template v-slot:prepend>
                    <v-icon class="mr-3">mdi-calendar</v-icon>
                  </template>
                  <v-list-item-title>发生时间</v-list-item-title>
                  <template v-slot:append>
                    <v-list-item-subtitle>{{ formattedDate }}</v-list-item-subtitle>
                  </template>
                </v-list-item>
                <!-- 时间差显示 -->
                <v-list-item class="pl-9 pt-0 pb-2">
                  <v-list-item-subtitle class="text-caption text-grey-darken-1">{{ timeAgoString }}</v-list-item-subtitle>
                </v-list-item>
                <!-- 注释掉"置顶"开关 -->
                <!--
                <v-divider class="my-2"></v-divider>
                <v-list-item class="px-0">
                  <template v-slot:prepend>
                    <v-icon class="mr-3">mdi-pin</v-icon>
                  </template>
                  <v-list-item-title>置顶</v-list-item-title>
                  <template v-slot:append>
                    <v-switch
                      v-model="isHighlight"
                      color="primary"
                      hide-details
                      inset
                    ></v-switch>
                  </template>
                </v-list-item>
                -->
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- 灵感库弹窗 -->
  <v-bottom-sheet v-model="inspirationDialog">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>灵感库</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="inspirationDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- 灵感库内容 -->
        <p>这里是灵感库的内容...</p>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>

  <!-- 日期选择器弹窗 -->
  <v-dialog v-model="dateMenu" max-width="290px">
    <v-date-picker
      v-model="date"
      @update:model-value="dateMenu = false"
      full-width 
    ></v-date-picker>
  </v-dialog>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import draggable from 'vuedraggable';
import { getTimeAgoString, formatDateForServer } from '@/utils/dateUtils';
import storyApi from '@/services/storyApi';
import { v4 as uuidv4 } from 'uuid';

const store = useStore();
const route = useRoute();

const props = defineProps({
  images: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(['close', 'update:images', 'publish']);

const dialog = ref(false);
const postContent = ref('');
const date = ref(new Date());
const dateMenu = ref(false);

const localImages = ref([...props.images]);

const formattedDate = computed(() => {
  return new Date(date.value).toLocaleDateString('zh-CN', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
});

// 获取故事开始时间
const storyStartDate = computed(() => {
  const currentStory = store.getters['story/currentStory'];
  return currentStory ? new Date(currentStory.startTime) : new Date();
});

// 修改 timeAgoString 计算属性
const timeAgoString = computed(() => {
  return getTimeAgoString(storyStartDate.value, date.value);
});

const closeDialog = () => {
  dialog.value = false;
  setTimeout(() => emit('close'), 300);
};

const canPublish = computed(() => {
  const hasContent = postContent.value.trim() !== '' || localImages.value.length > 0;
  const isUploading = localImages.value.some(img => img.isUploading);
  const allUploaded = localImages.value.every(img => img.serverUrl);
  return hasContent && !isUploading && allUploaded;
});

const publishPost = async () => {
  if (!canPublish.value) return;

  try {
    const fileList = localImages.value
      .filter(img => img.serverUrl)
      .map(img => img.serverUrl);

    const feedData = {
      content: postContent.value,
      fileList: fileList,
      feedTime: formatDateForServer(date.value),
      batchId: uuidv4(),
      storyId: parseInt(route.params.id)
    };

    await store.dispatch('story/commitFeed', feedData);
    
    // 使用 eventBus 或其他方式显示成功消息
    // eventBus.emit('show-snackbar', '发布成功');
    closeDialog();
  } catch (error) {
    console.error('发布失败:', error);
    // 显示错误消息
    // eventBus.emit('show-snackbar', '发布失败：' + error.message);
  }
};

const uploadImage = async () => {
  console.log('======== uploadImage')
  if (localImages.value.length >= 100) return;
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = 'image/*';
  input.multiple = true;
  input.onchange = async (event) => {
    const files = event.target.files;
    if (files) {
      const newImages = Array.from(files).map(file => ({
        url: URL.createObjectURL(file),
        isUploading: true,
        uploadFailed: false,
        file: file
      }));

      localImages.value = [...localImages.value, ...newImages].slice(0, 100);
      console.log('======== uploadImage -> localImages.value')
      for (let newImage of newImages) {
        await uploadSingleImage(newImage);
      }
    }
  };
  input.click();
};

const uploadSingleImage = async (image) => {
  console.log('======== uploadSingleImage',image)
  try {
    const response = await storyApi.uploadImage(image.file);
    if (response.result === 0 && response.body && response.body.list && response.body.list.length > 0) {
      const uploadedImage = response.body.list[0];
      const index = localImages.value.findIndex(img => img.url === image.url);
      if (index !== -1) {
        localImages.value[index] = {
          ...localImages.value[index],
          isUploading: false,
          uploadFailed: false,
          serverUrl: uploadedImage.pictureUrls,
          dateTime: uploadedImage.dateTime
        };
        // 如果这是第一张图片，更新日期
        if (index === 0) {
          updateDate(uploadedImage.dateTime);
        }
      }
    } else {
      throw new Error('上传失败');
    }
  } catch (error) {
    console.error('上传图片出错:', error);
    const index = localImages.value.findIndex(img => img.url === image.url);
    if (index !== -1) {
      localImages.value[index] = {
        ...localImages.value[index],
        isUploading: false,
        uploadFailed: true
      };
    }
  }
};

const retryUpload = async (image) => {
  const index = localImages.value.findIndex(img => img.url === image.url);
  if (index !== -1) {
    localImages.value[index] = {
      ...localImages.value[index],
      isUploading: true,
      uploadFailed: false
    };
    await uploadSingleImage(localImages.value[index]);
  }
};

const deleteImage = (image) => {
  const index = localImages.value.findIndex(img => img.url === image.url);
  if (index > -1) {
    localImages.value.splice(index, 1);
    emit('update:images', localImages.value);
    // 如果删除后没有图片了，重置日期为今天
    if (localImages.value.length === 0) {
      updateDate(new Date());
    }
  }
};

const singleImageAspectRatio = ref(1);
const singleImage = ref(null);

const singleImageStyle = computed(() => {
  if (singleImageAspectRatio.value >= 3/4) {
    // 如果图片比例大于或等于 3:4，使实际比例
    return {
      paddingBottom: `${(1 / singleImageAspectRatio.value) * 100}%`,
    };
  } else {
    // 如果图片比例小 3:4，固定高度为宽度的 4/3
    return {
      paddingBottom: '133.33%', // 4:3 的反比
    };
  }
});

const onSingleImageLoad = () => {
  if (singleImage.value) {
    const img = singleImage.value;
    singleImageAspectRatio.value = img.naturalWidth / img.naturalHeight;
  }
};

// 更新日期的函数
const updateDate = (newDate) => {
  date.value = new Date(newDate);
};

// 监听 date 的变化
watch(date, () => {
  // date 变化时，timeAgoString 会自动重新计算
  console.log('发生时间已更新，新的时间差:', timeAgoString.value);
});

// 监听 localImages 的变化
watch(localImages, (newImages) => {
  if (newImages.length > 0 && newImages[0].dateTime) {
    updateDate(newImages[0].dateTime);
  } else if (newImages.length === 0) {
    updateDate(new Date());
  }
}, { deep: true });

onMounted(() => {
  console.log('Mounted EditPost, images:', localImages.value);
  // 如果有传入的图片，立即开始上传
  if (localImages.value.length > 0) {
    localImages.value.forEach(uploadSingleImage);
  }
  // 如果没有图片，设置默认日期为今天
  if (localImages.value.length === 0) {
    updateDate(new Date());
  } else if (localImages.value[0].dateTime) {
    // 如果有图片，使用第一张图片的日期
    updateDate(localImages.value[0].dateTime);
  }
  setTimeout(() => {
    dialog.value = true;
  }, 0);
});
</script>

<style scoped>
.edit-post-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.edit-post-toolbar {
  border-bottom: 1px solid #e0e0e0;
}

.image-grid-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.image-grid {
  display: flex;
  flex-wrap: nowrap;
  padding: 8px 0;
}

.image-item {
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  margin-right: 8px;
  position: relative;
  overflow: hidden;
}

.preview-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-action-btn {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5) !important;
  padding: 0 !important;
  transition: background-color 0.3s ease;
}

.image-action-btn:hover {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.delete-btn {
  top: 4px;
  right: 4px;
  width: 24px !important;
  height: 24px !important;
}

.post-content {
  border: none;
  font-size: 14px;
}

/* 使用深度选择器来修改 Vuetify 组件的样式 */
:deep(.v-text-field .v-input__control) {
  min-height: 40px;
}

:deep(.v-text-field .v-input__slot) {
  min-height: 40px;
}

.single-image-container {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.single-preview-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.single-preview-img.crop {
  object-fit: cover;
}

.v-list {
  background-color: transparent !important;
}

.v-list-item {
  min-height: 48px;
  padding-left: 0;
  padding-right: 0;
}

.v-list-item__prepend {
  margin-right: 0;
}

.v-list-item__append {
  margin-left: 16px;
}

/* 调整时间差显示的样式 */
.v-list-item-subtitle.text-caption {
  padding-top: 0;
  padding-bottom: 0;
}

/* 添加过渡画 */
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.3s ease-in-out;
}

.dialog-bottom-transition-enter-from,
.dialog-bottom-transition-leave-to {
  transform: translateY(100%);
}

/* ... 其他样式保持不变 ... */

.image-upload-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.retry-btn {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 36px !important;
  height: 36px !important;
  background-color: rgba(255, 0, 0, 0.7) !important;
}

.retry-btn:hover {
  background-color: rgba(255, 0, 0, 0.9) !important;
}
</style>

