// import story from '@/store/modules/story';
import api from './api';

export default {
  // 获取故事详情
  getStoryDetails(storyId) {
    return api.get(`/story/${storyId}`);
  },

  // 获取故事列表
  getStoryList(pageNo = 1, pageSize = 10) {
    return api.post('/story/list', { pageNo, pageSize });
  },

  // 创建新故事
  createStory(storyData) {
    var title = storyData.title
    var img = storyData.img
    var startTime = storyData.startTime
    var endTime = storyData.endTime
    var leadRole = storyData.leadRole
    return api.post('/story/add', {title,img,startTime,endTime,leadRole});
  },

  // 更新故事信息
  updateStory(storyId, storyData) {
    return api.put(`/story/${storyId}`, storyData);
  },

  // 删除故事
  deleteStory(storyId) {
    var id = parseInt(storyId)
    return api.post('/story/delete', {id});
  },

  // 获取故事的帖子列表
  getStoryPosts(storyId, pageNo = 1, pageSize = 20) {
    return api.get(`/story/${storyId}/posts`, { params: { pageNo, pageSize } });
  },

  // 添加帖子到故事
  addPostToStory(storyId, postData) {
    return api.post(`/story/${storyId}/post`, postData);
  },

  // 在文件末尾添加这个新方法
  getFeedList(storyId, pageNo = 1, pageSize = 10) {
    return api.post('/feed/list', { storyId, pageNo, pageSize });
  },

  // 添加这个新方法
  uploadImage(file) {
    const formData = new FormData();
    formData.append('files', file);
    return api.post('/upload/oss', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  commitFeed(feedData) {
    return api.post('/feed/commit', feedData);
  },

  // 添加删除 feed 的方法
  deleteFeed(feedId) {
    return api.post('/feed/del', { id: feedId });
  }
};
