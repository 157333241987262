import api from './api';
import router from '@/router'; // 导入 router
import store from '@/store'; // 导入 store

const authService = {
  // 用户登录
  login: (email, password) => {
    console.log('### authService.js 登录：', email, password)
    return api.post('/user/login', { email, password });
  },

  // 用户注册
  register: (email, password) => {
    console.log('### authService.js 注册：', email, password)
    return api.post('/user/register', { email, password });
  },

  // 用户登出
  logout: () => {
    console.log('### authService.js 登出：')
    try {
      localStorage.removeItem('token');
      store.commit('auth/CLEAR_AUTH');
      console.log('清除后的 token:', localStorage.getItem('token'));
      router.push('/');
    } catch (error) {
      console.error('登出过程中发生错误:', error);
    }
  },

  // 获取我的故事列表
  getStoryList: (pageNo, pageSize) => {
    console.log('### authService.js 获取我的故事列表：')
    return api.post('/story/list',{pageNo, pageSize});
  },

  // 刷新 token
  refreshToken: () => {
    console.log('### authService.js 刷新 token：')
    return api.post('/user/refresh-token');
  }
};

export default authService;
