<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="album">
    <h1>我的相册</h1>
    <p v-if="albumId">当前相册ID：{{ albumId }}</p>
    <p v-else>用户发过的所有 feed 中的照片、单独上传的照片、收藏别人的图片都会出现在这里。相当于照片库</p>
    
    <!-- 添加测试按钮 -->
    <v-btn
      color="primary"
      @click="testFetchFeedList"
      :loading="loading"
    >
      测试获取Feed列表
    </v-btn>
  </div>
</template>

<script>
import { ref } from 'vue';
// import { useStore } from 'vuex';
import storyApi from '@/services/storyApi';

export default {
  name: 'Album',
  setup() {
    // const store = useStore();
    const loading = ref(false);

    const testFetchFeedList = async () => {
      loading.value = true;
      try {
        const response = await storyApi.getFeedList(5, 1, 10);
        console.log('Feed列表测试结果:', response);
      } catch (error) {
        console.error('获取Feed列表失败:', error);
      } finally {
        loading.value = false;
      }
    };

    return {
      loading,
      testFetchFeedList
    };
  }
}
</script>

<style scoped>
.album {
  padding: 20px;
}
</style>
