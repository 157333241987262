<template>
  <div class="post-item">
    <div v-if="showYear" class="year-display">
      <span class="year-text">{{ post.year }}</span>
    </div>
    <div class="post-content">
      <div class="timeline-left">
        <div class="timeline-line"></div>
        <div v-if="showDate" class="timeline-icon">
          <v-icon size="small">mdi-circle</v-icon>
        </div>
      </div>
      <div class="post-details" style="background-color: white;">
        <div v-if="showDate" class="post-date">
          <span class="date">{{ formatDateShort(post.feedTime) }}</span>
          <span class="time-ago">{{ getTimeAgo }}</span>
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                icon="mdi-dots-vertical"
                variant="text"
                v-bind="props"
                class="more-button"
                density="comfortable"
                size="small"
              ></v-btn>
            </template>
            <v-list>
              <v-list-item @click="confirmDelete">
                <v-list-item-title class="delete-option">删除</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="post-text-container">
          <p ref="textElement" class="post-text" :class="{ 'truncated': isTruncated && !isExpanded }">
            {{ post.content }}
          </p>
          <div v-if="isTruncated" @click="toggleExpand" class="expand-collapse">
            {{ isExpanded ? '收起' : '展开' }}
          </div>
        </div>
        <div v-if="post.fileList && post.fileList.length > 0" class="image-grid" :class="[`grid-${Math.min(post.fileList.length, 9)}`]">
          <template v-for="(image, imgIndex) in post.fileList.slice(0, 9)" :key="imgIndex">
            <div class="image-wrapper" :class="{ 'single-image': post.fileList.length === 1 }">
              <img 
                :src="getImageUrl(image)" 
                :class="['post-image', { 'single': post.fileList.length === 1 }]" 
                @click="$emit('open-image-viewer', post.fileList.map(getImageUrl), imgIndex)"
              />
              <div v-if="imgIndex === 8 && post.fileList.length > 9" class="more-images-overlay" @click="$emit('open-image-viewer', post.fileList.map(getImageUrl), 8)">
                +{{ post.fileList.length - 9 }}
              </div>
            </div>
          </template>
        </div>
        <div class="post-divider"></div>
      </div>
    </div>
    <v-dialog v-model="showDeleteDialog" max-width="300">
      <v-card>
        <v-card-title>确认删除</v-card-title>
        <v-card-text>您确定要删除这条记录吗？</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showDeleteDialog = false">取消</v-btn>
          <v-btn color="error" text @click="deletePost">删除</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { computed, ref, onMounted, nextTick } from 'vue';
import { useStore } from 'vuex';
import { formatDate, getTimeAgoString } from '@/utils/dateUtils';

export default {
  name: 'PostItem',
  props: {
    post: {
      type: Object,
      required: true
    },
    showYear: {
      type: Boolean,
      default: false
    },
    showDate: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const store = useStore();

    const showDeleteDialog = ref(false);
    const isExpanded = ref(false);
    const textElement = ref(null);
    const isTruncated = ref(false);

    const getImageUrl = (imagePath) => {
      const API_BASE_URL = 'https://comory-1308402519.cos.na-siliconvalley.myqcloud.com';
      return `${API_BASE_URL}/${imagePath}`;
    };

    const formatDateShort = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleString('zh-CN', {
        month: 'numeric',
        day: 'numeric'
      });
    };

    const getTimeAgo = computed(() => {
      const currentStory = store.getters['story/currentStory'];
      if (currentStory && currentStory.startTime && props.post.feedTime) {
        return getTimeAgoString(currentStory.startTime, props.post.feedTime);
      }
      return '';
    });

    const confirmDelete = () => {
      showDeleteDialog.value = true;
    };

    const deletePost = async () => {
      try {
        await store.dispatch('story/deleteFeed', props.post.id);
        showDeleteDialog.value = false;
        // 可以在这里添加一个成功删除的提示
        console.log('Feed 删除成功');
      } catch (error) {
        console.error('删除 Feed 失败:', error);
        // 可以在这里添加一个删除失败的提示
      }
    };

    const toggleExpand = () => {
      isExpanded.value = !isExpanded.value;
      nextTick(() => {
        checkTextTruncation();
      });
    };

    const checkTextTruncation = () => {
      if (textElement.value) {
        const lineHeight = parseInt(getComputedStyle(textElement.value).lineHeight);
        const maxHeight = lineHeight * 3; // 3行文字的高度
        isTruncated.value = textElement.value.scrollHeight > maxHeight;
        console.log('Is truncated:', isTruncated.value);
        console.log('Scroll height:', textElement.value.scrollHeight);
        console.log('Max height:', maxHeight);
      }
    };

    onMounted(() => {
      nextTick(() => {
        checkTextTruncation();
      });
      window.addEventListener('resize', checkTextTruncation);
    });

    return {
      formatDate,
      getImageUrl,
      formatDateShort,
      getTimeAgo,
      showDeleteDialog,
      confirmDelete,
      deletePost,
      isExpanded,
      isTruncated,
      toggleExpand,
      textElement,
      checkTextTruncation,
    };
  }
}
</script>

<style scoped>
.post-item {
  /* margin-bottom: 20px; */
}

.year-display {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  padding: 10px 15px;
  border-radius: 5px;
}

.year-text {
  background-color: palegreen;
  display: inline-block;
  text-align: left;
}

.post-content {
  display: flex;
  width: 100%;
  padding-right: 20px; /* 添加右侧内边距 */
  /* background-color: red; */
}

.timeline-left {
  width: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeline-line {
  position: absolute;
  width: 2px;
  background-color: #ccc;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.timeline-icon {
  position: relative;
  background-color: #ccc;
  z-index: 1;
  margin-top: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #ccc;
}

.timeline-icon .v-icon {
  font-size: 16px;
  color: #ccc;
}

.post-details {
  width: calc(100% - 40px);
  padding-left: 20px; /* 增加左侧内边距 */
  padding-top: 17px;
  padding-right: 20px; /* 添加右侧内边距 */
  box-sizing: border-box; /* 确保内边距不会增加元素的总宽度 */
}

.post-date {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.post-date .date {
  font-weight: bold;
  font-size: 18px;
  margin-right: 10px;
}

.post-date .time-ago {
  font-size: 14px;
  color: #888;
  font-weight: normal;
  flex-grow: 1;
}

.post-text-container {
  position: relative;
  margin-bottom: 20px; /* 增加底部间距 */
}

.post-text {
  margin-bottom: 10px;
  line-height: 1.5;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.post-text.truncated {
  max-height: 4.5em; /* 3行文字的高度 */
}

.expand-collapse {
  color: #007bff;
  cursor: pointer;
  text-align: left; /* 改为左对齐 */
  padding-top: 5px;
  font-size: 14px; /* 可选：调整字体大小 */
}

.image-grid {
  display: grid;
  gap: 4px;
  margin-bottom: 15px;
  max-width: 100%; /* 确保图片网格不会超出容器 */
}

.image-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
}

.post-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.single-image {
  padding-bottom: 0;
}

.post-image.single {
  position: static;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
}

.grid-1 {
  grid-template-columns: 1fr;
}

.grid-2, .grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-4 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-5, .grid-6, .grid-7, .grid-8, .grid-9 {
  grid-template-columns: repeat(3, 1fr);
}

.more-images-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
}

.post-divider {
  height: 1px;
  background-color: #e0e0e0;
  margin-top: 15px;
}

.more-button {
  min-width: 0;
  padding: 0;
  color: #bbb; /* 更浅的颜色 */
}

.more-button :deep(.v-btn__content) {
  font-size: 18px; /* 调整图标大小 */
}

.delete-option {
  color: red !important;
}
</style>
