/**
 * 路由的概念：
在单页应用（SPA）中，路由是一种管理不同视图（或页面）之间切换的机制，而不需要重新加载整个页面。它允许应用在用户与应用交互时更新 URL，同时相应地更新页面内容。

 * 
 * 三个关键概念：
 路径（Path）：
路径是 URL 的一部分，用于标识应用中的特定页面或视图。

组件映射（Component Mapping）：
这是将路径与特定 Vue 组件关联起来的过程。在上面的例子中，'Feed' 路由与 Feed 组件相关联。当用户访问 '/feed' 路径时，router 会渲染 Feed 组件。

导航守卫（Navigation Guards）：
这些是在路由过程中的某些时点触发的钩子函数。它们可以用来控制导航行为，例如检查用户是否有权限访问某个页面。
 */

import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import Feed from '../views/Feed.vue';
import Stories from '../views/Stories.vue';
import Album from '../views/Album.vue';
import StoryDetail from '../views/StoryDetail.vue';
import EditPost from '../views/EditPost.vue';
import UserProfile from '../views/UserProfile.vue'; // 新增用户个人页面
import SharedContent from '../views/SharedContent.vue'; // 新增公开分享页面
import NotFound from '../views/NotFound.vue'; // 添加这行

// 路径（Path）：
// 路径是 URL 的一部分，用于标识应用中的特定页面或视图。
// 这里，'/feed' 是路径，当用户访问 http://yourapp.com/feed 时，会显示 Feed 组件
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/feed',
    name: 'Feed',
    component: Feed,
    meta: { requiresAuth: true }
  },
  {
    path: '/user/:username',
    name: 'UserProfile',
    component: UserProfile,
    meta: { requiresAuth: true }
  },
  {
    path: '/album',  // 注意这里的路径
    name: 'Album',
    component: Album,
    meta: { requiresAuth: true }
  },
  {
    path: '/stories',
    name: 'Stories',
    component: Stories,
    meta: { requiresAuth: true }
  },
  {
    path: '/story/:id',
    name: 'StoryDetail',
    component: StoryDetail,
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-post',
    name: 'EditPost',
    component: EditPost,
    meta: { requiresAuth: true }
  },
  {
    path: '/share/:shareId',
    name: 'SharedContent',
    component: SharedContent
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound // 修改这行
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// 导航守卫（Navigation Guards）：
// 这些是在路由过程中的某些时点触发的钩子函数。它们可以用来控制导航行为，例如检查用户是否有权限访问某个页面。
// 这个全局前置守卫在每次导航之前被调用。它检查用户是否已认证，如果没有，可能会重定向到登录页面。
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  console.log('Navigation guard: Destination:', to.name, 'Auth status:', isAuthenticated);
  
  // 添加这个条件来暂时绕过身份验证（仅用于开发）
  const bypassAuth = process.env.NODE_ENV === 'development';

  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated && !bypassAuth) {
    console.log('Redirecting to home due to auth requirement');
    next('/');
  } else {
    console.log('Proceeding with navigation');
    next();
  }
});

export default router;