<template>
  <div id="app">
    <v-app>
      <v-navigation-drawer v-if="isAuthenticated" v-model="drawer" app>
        <Sidebar @close="closeDrawer" @navigate="handleNavigation" :current-route="$route.path" />
      </v-navigation-drawer>

      <v-app-bar 
        v-if="isAuthenticated" 
        app 
        flat 
        :class="[
          {'transparent-bar': (isFeedPage || isStoryDetailPage) && !isNavScrolled},
          {'scrolled-bar': (isFeedPage || isStoryDetailPage) && isNavScrolled},
          {'border-bottom': !isFeedPage && !isStoryDetailPage}
        ]"
      >
        <v-app-bar-nav-icon 
          @click="toggleDrawer"
          :color="getIconColor"
        ></v-app-bar-nav-icon>
        <v-toolbar-title 
          :class="[
            'font-weight-bold',
            'transition-title',
            {'transparent-text': (isFeedPage || isStoryDetailPage) && !isNavScrolled},
            {'black--text': (!isFeedPage && !isStoryDetailPage) || isNavScrolled}
          ]"
        >
          {{ pageTitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="isStoriesPage"
          color="primary"
          @click="openNewStoryModal"
        >
          新建
        </v-btn>
      </v-app-bar>

      <v-main class="main-background">
        <router-view @toggle-drawer="toggleDrawer" @open-new-story-modal="openNewStoryModal"></router-view>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { ref, watch, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Sidebar from '@/components/common/Sidebar.vue'
import { useRouter } from 'vue-router';
import { onErrorCaptured } from 'vue'

export default {
  name: 'App',
  components: {
    Sidebar
  },
  setup() {
    const drawer = ref(false)
    const pageTitle = ref('相册')
    const route = useRoute()
    const store = useStore()
    const router = useRouter();
    const isNavScrolled = ref(false);

    const isStoryDetailPage = computed(() => route.name === 'StoryDetail')
    const isAuthenticated = computed(() => {
      const authState = store.state.auth.isAuthenticated
      console.log('认证状态:', authState) // 添加日志
      return authState
    })

    const toggleDrawer = () => {
      drawer.value = !drawer.value
    }

    const closeDrawer = () => {
      drawer.value = false
    }

    // 这段代码定义了两个函数：handleNavigation 和一个 watch 函数

    // handleNavigation 函数用于处理导航事件
    // 它接收一个 title 参数，关闭抽屉菜单，并更新页面标题
    const handleNavigation = (title) => {
      console.log(`Handling navigation with title ${title}`);
      closeDrawer();
      pageTitle.value = title;
    }

    // watch 函数监听路由名称的变化
    // 当路由名称改变时，根据不同的路由更新页面标题
    watch(() => route.name, (newRouteName) => {
      switch(newRouteName) {
        case 'Feed':
          console.log('Feed')
          pageTitle.value = '动态'
          break
        case 'Album':
          console.log('Album')
          pageTitle.value = '相册'
          break
        case 'Stories':
          console.log('Stories')
          pageTitle.value = '故事'
          break
        case 'StoryDetail':
          console.log('StoryDetail')
          pageTitle.value = '故事详情'
          break
        case 'UserProfile':
          console.log('UserProfile')
          pageTitle.value = '个人主页'
          break
        default:
          pageTitle.value = '时间线相册'
      }
    })

    // 监视路由变化
    watch(() => router.currentRoute.value, (newRoute) => {
      console.log('Route changed:', newRoute);
    }, { immediate: true, deep: true });

    const isStoriesPage = computed(() => route.name === 'Stories')

    const openNewStoryModal = () => {
      // 触发一个事件，让 Stories 组件知道需要打开新建故事的模态框
      if (route.name === 'Stories') {
        router.app.config.globalProperties.$emit('open-new-story-modal');
      }
    }

    const isFeedPage = computed(() => route.name === 'Feed')

    onMounted(() => {
      console.log('### App.vue 组件已挂载，开始检查登录状态...')
      // 在组件挂载时检查并恢复认证状态
      store.dispatch('auth/checkAuth')

      // 监听导航栏状态更新事件
      window.addEventListener('update-navbar', (event) => {
        isNavScrolled.value = event.detail.isScrolled;
      });
    })

    onErrorCaptured((err, instance, info) => {
      console.error('捕获到错误:', err, instance, info)
      // 这里可以添加错误处理逻辑，比如显示一个错误通知
      return false // 阻止错误继续传播
    })

    // 计算图标颜色
    const getIconColor = computed(() => {
      if (isFeedPage.value || isStoryDetailPage.value) {
        return isNavScrolled.value ? 'black' : 'white';
      }
      return 'black';
    });

    return {
      drawer,
      pageTitle,
      handleNavigation,
      toggleDrawer,
      closeDrawer,
      isStoryDetailPage,
      isAuthenticated,
      isStoriesPage,
      openNewStoryModal,
      isFeedPage,
      isNavScrolled,
      getIconColor
    }
  }
}
</script>

<style>
/* 全局样式 */
.main-background {
  background-color: white; /* 设置黄色背景 */
}

.transparent-bar {
  background-color: transparent !important;
  border-bottom: none !important;
  transition: all 0.3s ease;
}

.scrolled-bar {
  background-color: white !important; /* 蓝色背景 */
  border-bottom: none !important;
  transition: all 0.3s ease;
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0;
}

/* 添加过渡效果 */
.v-app-bar {
  transition: all 0.3s ease !important;
}

.v-toolbar-title {
  transition: color 0.3s ease !important;
}

.v-btn {
  transition: color 0.3s ease !important;
}

.transition-title {
  transition: opacity 0.3s ease, color 0.3s ease;
}

.transparent-text {
  opacity: 0;
}
</style>
