<template>
  <div class="shared-content">
    <h1>分享的内容</h1>
    <p>分享ID: {{ $route.params.shareId }}</p>
    <!-- 这里可以添加分享内容的展示逻辑 -->
  </div>
</template>

<script>
export default {
  name: 'SharedContent',
  // 如果需要，可以在这里添加更多逻辑
}
</script>

<style scoped>
/* 可以添加样式 */
</style>