<template>
  <transition name="fade">
    <div v-if="show" class="guide-overlay">
      <div class="guide-content">
        <h1 class="guide-title">My story begins on ...</h1>
        <p class="guide-subtitle">Please select your birthdate.</p>
        <div class="date-selectors">
          <select v-model="year" class="date-select" :class="{ 'placeholder': !year }">
            <option value="" disabled selected>年</option>
            <option v-for="y in years" :key="y" :value="y">{{ y }}</option>
          </select>
          <select v-model="month" class="date-select" @change="updateDays" :class="{ 'placeholder': !month }">
            <option value="" disabled selected>月</option>
            <option v-for="m in 12" :key="m" :value="m">{{ m }}</option>
          </select>
          <select v-model="day" class="date-select" :class="{ 'placeholder': !day }">
            <option value="" disabled selected>日</option>
            <option v-for="d in days" :key="d" :value="d">{{ d }}</option>
          </select>
        </div>
        <p class="guide-info">myMemoir will create a timeline album based on your birthdate.</p>
        <button @click="startStory" class="start-button" :disabled="!isDateValid">Story Start</button>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { formatDateForServer } from '@/utils/dateUtils'; // 导入 formatDateForServer 函数

export default {
  name: 'GuideOverlay',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close', 'start'],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const currentYear = new Date().getFullYear();
    const years = ref([]);
    const year = ref('');
    const month = ref('');
    const day = ref('');
    const days = ref([]);

    const updateDays = () => {
      if (year.value && month.value) {
        const daysInMonth = new Date(year.value, month.value, 0).getDate();
        days.value = Array.from({ length: daysInMonth }, (_, i) => i + 1);
        if (day.value > daysInMonth) {
          day.value = '';
        }
      } else {
        days.value = [];
      }
    };

    onMounted(() => {
      years.value = Array.from({ length: currentYear - 1899 }, (_, i) => currentYear - i);
    });

    watch([year, month], updateDays);

    const isDateValid = computed(() => {
      return year.value && month.value && day.value;
    });

    const startStory = async () => {
      if (isDateValid.value) {
        const birthdate = `${year.value}-${month.value.toString().padStart(2, '0')}-${day.value.toString().padStart(2, '0')}`;
        try {
          // 创建一个新的 Date 对象，并明确设置时间为 00:00:00
          const birthdateObj = new Date(birthdate);
          birthdateObj.setHours(0, 0, 0, 0);

          // 使用 formatDateForServer 格式化 birthdate
          const formattedBirthdate = formatDateForServer(birthdateObj);

          const storyData = {
            title: `My Story`,
            startTime: formattedBirthdate,
            lead: "我",
            endTime: null,
          };

          const newStory = await store.dispatch('story/createStory', storyData);
          console.log('创建成功', newStory);
// "{"storyId":26,"content":"我出生了","postTime":"2023-02-03 00:00:00","images":[]}"
          // 创建出生 post
          const birthPost = {
            storyId: newStory.id,
            content: "我出生了",
            feedTime: formattedBirthdate,
            fileList: []
          };

          await store.dispatch('story/commitFeed', birthPost);

          // 更新 store 中的 currentStory
          store.commit('story/SET_CURRENT_STORY', newStory);

          // 刷新故事列表
          await store.dispatch('story/fetchStoryList');

          // 导航到新创建的故事详情页
          router.push({ 
            name: 'StoryDetail', 
            params: { id: newStory.id }
          });

          emit('close');
        } catch (error) {
          console.error('创建故事或发布出生 post 失败:', error);
          // 这里可以添加错误处理，比如显示一个错误消息
        }
      }
    };

    return { 
      year, month, day, years, days,
      isDateValid, updateDays, startStory 
    };
  }
}
</script>

<style scoped>
.guide-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.guide-content {
  text-align: center;
  padding: 20px;
  width: 80%;
  max-width: 400px;
}

.guide-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.guide-subtitle {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
}

.date-selectors {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.date-select {
  width: 30%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  appearance: none;
  background: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>') no-repeat;
  background-position: right 10px top 50%;
  background-size: 20px auto;
}

.date-select.placeholder {
  color: #999;
}

.date-select option {
  color: #000;
}

.guide-info {
  font-size: 14px;
  color: #999;
  margin-bottom: 30px;
}

.start-button {
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.start-button:hover {
  background-color: #3a7bc8;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
